import React, { useEffect, useState } from "react";
import { location, userIcon } from "./assets";
import "./consolestyle.css";
import jwt_decode from "jwt-decode";
import { Redirect } from 'react-router-dom'

export const ClinicAPI = require("./ClinicService.web");

export default function Navigation() {
  var token = window.localStorage.getItem("token") || null;

  const [decoded, setDecoded] = useState({ id: "" });
  const [userData, setUserData] = useState({ attributes: { first_name: "", residential_address: "" } });

  const [redirect, setRedirect] = useState(false)


  useEffect(() => {
    if (token !== null) {
      setDecoded(jwt_decode(token ? token : ""));
    } else {
      setRedirect(true)
    }

  }, [token]);

  useEffect(() => {
    if (decoded.id !== "") {
      getUserDetails();
    }
  }, [decoded.id]);

  useEffect(() => {
    window.localStorage.setItem('Name', userData?.attributes?.first_name)
  }, [userData])

  const getUserDetails = async () => {
    const result = await ClinicAPI.getAccountDetails(decoded);
    if (result.success) {
      localStorage.setItem("recipientId", result?.data?.id)
      setUserData(result.data);
    } else {
      console.log("error from create recipient:", result.error);
    }
  };



  const handleLogout = () => {
    window.localStorage.clear()
    setRedirect(true)
  }

  if (redirect) {
    return <Redirect to="/" />
  }

  return (
    <div className="container">
      <div className="row mt-4">
        <div className="col-9 row">
          <div className="nav-heading">Covid Passport</div>
          <span className="ml-4 mt-3 location-name location-icon">
            <img src={location} /> {userData?.attributes?.residential_address}
          </span>
        </div>
        <div className="col-3 btn-area">
          <div className="dropdown">
            <img src={userIcon} className="user-icon" alt="user-icon" />
            <button
              className="dropdown-toggle logout-btn"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {userData && userData?.attributes?.first_name}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <button onClick={() => handleLogout()} className="dropdown-item" >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
      </div>
    </div>
  );
}
