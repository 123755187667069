import React from "react";

//Customizable Area Start
import { imgVector } from "./assets";
import "../../scss/style.scss";
// import Header from "../../RegistrationMultipageForm/src/Header.web";
import { Formik } from "formik";
import * as Yup from "yup";

import ForgotPasswordController, { Props } from "./ForgotPasswordController";
//Customizable Area End

export default class RequestSubmission extends ForgotPasswordController {
  render() {
    return (
      <div className="main-wrapper">
        {/* <Header /> */}
        {/* <div className="flex-between-center">
                            <div className="flex-center">
                                <h2>Covid Passport</h2>
                                <div className="flex-center Your-location">
                                    <img src={require("../assets/location-icon.png")} alt="" /> Your Location
                                    </div>
                            </div>
                            <div className="flex-center user-profile">
                                <img src={require("../assets/11.jpg")} alt="" />
                                <p className="username">Rahul Jain</p>
                                <div className="options">
                                    <img src={require("../assets/down-arrow.svg")} alt="" />
                                </div>
                            </div>
                        </div> */}
        <div className="container">
          <div className="">
            <div className="row">
              <div className="form-wrapper flex-between-center text-center">
                <div className="image-box col-6">
                  <img style={{width: '75%'}} src={require("../assets/resubmission-image.png")} />
                </div>
                <div className="content-box col-6">
                  <img src={require("../assets/check.png")} />
                  <h2 className="mb-5 mt-5 color-orange">
                    Your request has been
                    <br /> successfully submitted.
                  </h2>
                  <div className="text-center mb-5">
                    <p>
                      You will be notified soon by a confirmation on your email.
                    </p>
                  </div>
                  <button type="button" className="btn dashboard-btn2 btn-grey small mt-3">
                    BACK TO DASHBOARD
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
