// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import Sms2 from '../../blocks/Sms2/src/Sms2';
import AdvancedSearch2 from '../../blocks/AdvancedSearch2/src/AdvancedSearch2';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/mobile-input/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/user-additional-detail-input/AdditionalDetailForm';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';

import ReviewAndApproval from '../../blocks/ReviewAndApproval/src/ReviewAndApproval';
import CustomForm from '../../blocks/CustomForm/src/CustomForm';
import LinkShare from '../../blocks/LinkShare/src/LinkShare';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import UploadMedia2 from '../../blocks/UploadMedia2/src/UploadMedia2';
import LandingPage from '../../blocks/LandingPage/src/LandingPage';
import Location from '../../blocks/Location/src/Location';
import ApiIntegration from '../../blocks/ApiIntegration/src/ApiIntegration';
import EmailNotifications2 from '../../blocks/EmailNotifications2/src/EmailNotifications2';
import CarouselDisplay0 from '../../blocks/CarouselDisplay0/src/CarouselDisplay0';
import Notes from '../../blocks/Notes/src/Notes';
import PeopleManagement from '../../blocks/PeopleManagement/src/PeopleManagement';
import PhoneVerification2 from '../../blocks/PhoneVerification2/src/PhoneVerification2';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import DataImportexportcsv2 from '../../blocks/DataImportexportcsv2/src/DataImportexportcsv2';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import QrCodes from '../../blocks/QrCodes/src/QrCodes';
import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole';
import EmailAccountRegistration1 from '../../blocks/email-account-registration/src/EmailAccountRegistrationStep1.web';
import EmailAccountRegistration2 from '../../blocks/email-account-registration/src/EmailAccountRegistrationStep2.web';
import EmailAccountRegistration3 from '../../blocks/email-account-registration/src/EmailAccountRegistrationStep3.web';
import EmailAccountRegistration4 from '../../blocks/email-account-registration/src/EmailAccountRegistrationStep4.web';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration.web';
import UserConsole from '../../blocks/AdminConsole/src/UserConsole.web';
import ClinicConsole from '../../blocks/AdminConsole/src/ClinicConsole.web';

//web
//import RegistrationMultipageForm from '../../blocks/RegistrationMultipageForm/src/RegistrationMultipageForm';
import RegistrationMultipageForm from '../../blocks/RegistrationMultipageForm/src/RegistrationMultipageForm.web';
import EmailLogin from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import RequestSubmission from '../../blocks/forgot-password/src/RequestSubmission.web';
import SurveyPersonalDetails from '../../blocks/RegistrationMultipageForm/src/SurveyPersonalDetails.web';
import Survey from '../../blocks/RegistrationMultipageForm/src/Survey.web';
import SurveySubmission from '../../blocks/RegistrationMultipageForm/src/SurveySubmission.web';
import ClinicSurveyScreen from '../../blocks/RegistrationMultipageForm/src/ClinicSurveyScreen1.web';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOtp.web';
import NewPassword from '../../blocks/forgot-password/src/NewPassword.web';
import PrivacyPolicy from '../../components/src/PrivacyPolicy.web';
import TermsOfServices from '../../components/src/TermsOfServices.web';

const routeMap = {
  UserConsole: {
    component: UserConsole,
    path: '/UserConsole'
  },

  ClinicConsole: {
    component: ClinicConsole,
    path: '/ClinicConsole'
  },

  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  ClinicSurveyScreen: {
    component: ClinicSurveyScreen,
    path: '/ClinicSurveyScreen'
  },
  Survey: {
    component: Survey,
    path: '/Survey'
  },
  SurveyPersonalDetails: {
    component: SurveyPersonalDetails,
    path: '/SurveyPersonalDetails'
  },
  SurveySubmission: {
    component: SurveySubmission,
    path: '/SurveySubmission'
  },
  RegistrationMultipageForm: {
    component: RegistrationMultipageForm,
    path: '/RegistrationMultipageForm'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  Sms2: {
    component: Sms2,
    path: '/Sms2'
  },
  AdvancedSearch2: {
    component: AdvancedSearch2,
    path: '/AdvancedSearch2'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  RequestSubmission: {
    component: RequestSubmission,
    path: '/RequestSubmission'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: '/ReviewAndApproval'
  },
  CustomForm: {
    component: CustomForm,
    path: '/CustomForm'
  },
  LinkShare: {
    component: LinkShare,
    path: '/LinkShare'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: '/UploadMedia2'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  Location: {
    component: Location,
    path: '/Location'
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration'
  },
  EmailNotifications2: {
    component: EmailNotifications2,
    path: '/EmailNotifications2'
  },
  CarouselDisplay0: {
    component: CarouselDisplay0,
    path: '/CarouselDisplay0'
  },
  Notes: {
    component: Notes,
    path: '/Notes'
  },
  PeopleManagement: {
    component: PeopleManagement,
    path: '/PeopleManagement'
  },
  PhoneVerification2: {
    component: PhoneVerification2,
    path: '/PhoneVerification2'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  DataImportexportcsv2: {
    component: DataImportexportcsv2,
    path: '/DataImportexportcsv2'
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },
  QrCodes: {
    component: QrCodes,
    path: '/QrCodes'
  },
  AdminConsole: {
    component: AdminConsole,
    path: '/AdminConsole'
  },
  // EmailAccountRegistration: {
  //   component: EmailAccountRegistration,
  //   path: '/EmailAccountRegistration'
  // },
  EmailLogin: {
    component: EmailLogin,
    // path: '/email-login'
    path: '/',
    exact: true
  },

  // EmailAccountRegistration1: {
  //   component: EmailAccountRegistration1,
  //   path: "/EmailAccountRegistration1"
  // },

  EmailAccountRegistration2: {
    component: EmailAccountRegistration2,
    path: '/EmailAccountRegistration2'
  },
  EmailAccountRegistration3: {
    component: EmailAccountRegistration3,
    path: '/EmailAccountRegistration3'
  },
  EmailAccountRegistration4: {
    component: EmailAccountRegistration4,
    path: '/EmailAccountRegistration4'
  },
  Home: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },

  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: '/privacy-policy',
    exact: true
  },
  TermsOfServices: {
    component: TermsOfServices,
    path: '/terms-of-services',
    exact: true
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
