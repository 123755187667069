import * as yup from "yup";
import getValidations from "./validation";

const otp = () => {
  const validations = getValidations();
  return yup.object().shape({
    otp: validations.otp.required("Password is required."),
  });
};
const forgotPassword = () => {
  const validations = getValidations();
  return yup.object().shape({
    password: validations.password.required("Password is required."),
    confirmpassword: validations.confirmpassword.required(
      "Password is required."
    ),
  });
};
export { otp, forgotPassword };
