import axios from "axios";
import { openNotification, openErrorNotification } from "./Notification.web";
// import { useHistory } from 'react-router-dom';
export const configJSON = require("./config");

const userSignIn = async function(payload) {
  const token = localStorage.getItem("token");
  try {
    const data = {
      data: {
        type: "email_account",
        attributes: payload,
      },
    };
    const response = await axios.post(
      `${configJSON.serverURL}bx_block_login/logins`,
      data,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    openErrorNotification(e && e.response && e.response.data && e.response.data.errors[0].failed_login,
      "error"
    );
    return { success: false, error: e };
  }
};

export { userSignIn };
