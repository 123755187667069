import axios from 'axios';
import map from 'lodash/map';
import { openErrorNotification } from './Notification.web';

export const configJSON = require('./config');

const userSignUp = async function (
  payload,
  password,
  userType,
  imageUrl,
  address
) {
  try {
    const data = {
      data: {
        type: 'email_account',
        attributes: {
          email: payload?.step1?.email,
          password: password?.confirmPassword,
          full_phone_number: `+91${payload?.step1?.contactNumber}`,
          first_name: payload?.step1?.fullName,
          role: userType,
          residential_address: payload?.step2?.residentialAddress || address,
          image_urls: imageUrl,
        },
      },
    };
    const response = await axios.post(
      `${configJSON.serverURL}account_block/accounts`,
      data,
      {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    console.log(e.response.data);
    if (e?.response?.data?.errors) {
      map(e.response.data.errors, (error) => {
        openErrorNotification(Object.values(error)[0], 'error');
      });
    } else if (e?.response?.data?.message) {
      openErrorNotification(
        e && e.response && e.response.data && e.response.data.message,
        'error'
      );
    } else {
      openErrorNotification(
        'Something went wrong, Please try again later',
        'error'
      );
    }
    return { success: false, error: e };
  }
};

const resendOtp = async function (email) {
  try {
    const data = {
      data: {
        attributes: {
          email: email,
        },
      },
    };
    const response = await axios.post(
      `${configJSON.serverURL}account_block/accounts/resend_otp`,
      data,
      {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    console.log(e.response.data);
    if (e?.response?.data?.errors) {
      map(e.response.data.errors, (error) => {
        openErrorNotification(Object.values(error)[0], 'error');
      });
    } else if (e?.response?.data?.message) {
      openErrorNotification(
        e && e.response && e.response.data && e.response.data.message,
        'error'
      );
    } else {
      openErrorNotification(
        'Something went wrong, Please try again later',
        'error'
      );
    }
    return { success: false, error: e };
  }
}

const otpVerification = async function (payload, userId) {
  try {
    const response = await axios.put(
      `${configJSON.serverURL
      }account_block/accounts/${userId}/email_verification`,
      {
        otp: payload.otp,
      },
      {
        headers: {
          token: window.localStorage.getItem('token'),
        },
      }
    );

    return { success: true, ...response.data };
  } catch (e) {
    if (e?.response?.data?.errors) {
      map(e.response.data.errors, (error) => {
        openErrorNotification(Object.values(error)[0], 'error');
      });
    } else if (e?.response?.data?.message) {
      openErrorNotification(
        e && e.response && e.response.data && e.response.data.message,
        'error'
      );
    } else {
      openErrorNotification('Invalid Otp', 'error');
    }
    return { success: false, error: e };
  }
};

const fileUpload = async function (payload) {
  try {
    const response = await axios.put(
      `${configJSON.serverURL}image_block/get_public_url?new_user=true`,
      payload,
      {}
    );

    return { success: true, ...response.data };
  } catch (e) {
    if (e?.response?.data?.errors) {
      map(e.response.data.errors, (error) => {
        openErrorNotification(Object.values(error)[0], 'error');
      });
    } else if (e?.response?.data?.message) {
      openErrorNotification(
        e && e.response && e.response.data && e.response.data.message,
        'error'
      );
    } else {
      openErrorNotification('Failed to Upload', 'error');
    }
    return { success: false, error: e };
  }
};

const getAddress = async function (lat, lng) {
  try {
    const response = await axios.get(
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lng}&localityLanguage=en`
    );

    return { success: true, ...response.data };
  } catch (e) {
    if (e?.response?.data?.errors) {
      map(e.response.data.errors, (error) => {
        openErrorNotification(Object.values(error)[0], 'error');
      });
    } else if (e?.response?.data?.message) {
      openErrorNotification(
        e && e.response && e.response.data && e.response.data.message,
        'error'
      );
    } else {
      openErrorNotification('Failed to Upload', 'error');
    }
    return { success: false, error: e };
  }
};

export { userSignUp, otpVerification, fileUpload, getAddress, resendOtp };
