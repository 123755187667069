import React from "react";
import { Link } from "react-router-dom";
import { bg1, check } from "./assets";
import "./regmstyle.css";

import RegistrationMultipageFormController, {
  Props,
  configJSON,
} from "./RegistrationMultipageFormController";
import Navigation from "../../AdminConsole/src/Navigation.web";

export default class SurveySubmission extends RegistrationMultipageFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (

      <div className="container">
        <div className="main-bg container">
          <Navigation />
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <div className="page-image">
                  <img className="submission-image" src={bg1} />
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="parent">
                  <div>
                    <img className="image1" src={check} />
                  </div>
                  <div className="submission-message mt-5">
                    <div className="submission-text-group">
                      <div className="submission-text1">Thank You!</div>
                      <div className="submission-text2">Your survey has been</div>
                      <div className="submission-text3">successfully submitted.</div>
                    </div>
                    <div className="btn-wrapper">
                      <Link
                        to="/UserConsole"
                        className="form-control-1 col-4 mt-5 dashboard-btn"
                      >
                        GO TO DASHBOARD
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
