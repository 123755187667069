import axios from 'axios';
import map from 'lodash/map';

import { openNotification, openErrorNotification } from './Notification.web';
export const configJSON = require('./config');

const userForgotPass = async function(payload) {
  const token = localStorage.getItem('token');
  try {
    const data = {
      data: {
        attributes: payload,
      },
    };
    const response = await axios.post(
      `${configJSON.serverURL}forgot_password/otp`,
      data,
      {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    console.log(e.response);
    if (e?.response?.data?.errors) {
      map(e.response.data.errors, (error) => {
        openErrorNotification(Object.values(error)[0], 'error');
      });
    } else {
      openErrorNotification('Error', 'error');
    }

    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};

const otpVerification = async function(payload) {
  console.log('payload====>', payload);
  try {
    const response = await axios.post(
      `${configJSON.serverURL}forgot_password/otp_confirmation`,
      {
        otp_code: payload.otp,
      },
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      }
    );

    return { success: true, ...response.data };
  } catch (e) {
    if (e?.response?.data?.errors) {
      map(e.response.data.errors, (error) => {
        openErrorNotification(Object.values(error)[0], 'error');
      });
    } else {
      openErrorNotification('Invalid Otp', 'error');
    }
    return { success: false, error: e };
  }
};

const setPassword = async function(payload) {
  console.log('payload====>', payload);
  try {
    const response = await axios.post(
      `${configJSON.serverURL}forgot_password/password`,
      {
        new_password: payload.new_password,
      },
      {
        headers: {
          token: localStorage.getItem('token'),
        },
      }
    );

    return { success: true, ...response.data };
  } catch (e) {
    if (e?.response?.data?.errors) {
      map(e.response.data.errors, (error) => {
        openErrorNotification(Object.values(error)[0], 'error');
      });
    } else {
      openErrorNotification('Error', 'error');
    }
    return { success: false, error: e };
  }
};

export { userForgotPass, otpVerification, setPassword };
