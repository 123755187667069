import { openNotification, openErrorNotification } from "./Notification.web";
import RegistrationMultipageFormController, {
  Props,
  configJSON,
} from "./RegistrationMultipageFormController";
import React from "react";
import SurveyPersonalDetails from "./SurveyPersonalDetails.web";
const userGetAllQuestions = require("./UserSurveyFormService.web");

export default class RegistrationMultipageForm extends RegistrationMultipageFormController {
  constructor(props: Props) {
    super(props);
  }
  // getAllQuestions = async () => {
  //   const result = await userGetAllQuestions.userGetAllQuestions();
  //   if (result.success) {
  //     this.setState({
  //       questionData: result,
  //     });
  //     console.log("result", result);
  //     console.log("this.state.questionData", this.state.questionData);
  //     openNotification("Successfully get all questions", "success");
  //     // this.props.navigation.history.push(`/dashboard`);
  //   } else {
  //     console.log("error from error :", result.error);
  //   }
  // };
  // async componentDidMount() {
  //   this.getAllQuestions();
  // }

  render() {
    return (
      <div>
        <SurveyPersonalDetails />
      </div>
    );
  }
}
