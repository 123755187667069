import * as yup from 'yup';
import getValidations from './validation';



const signUpSchema1 = () => {
    const validations = getValidations();
    return yup.object().shape({
        fullName: validations.name.required("Full Name is required."),
        email: validations.email.required("Email is required."),
        contactNumber:validations.phone.required("Contact Number is required.")
    });
}

const signUpSchema2 = () => {
    const validations = getValidations();
    return yup.object().shape({
        residentialAddress: validations.address.required("Address is required."),
        idProof: validations.file.required("File is required."),
    });
}

const signUpSchema4 = () => {
    const validations = getValidations();
    return yup.object().shape({
        otp: validations.otp.required("Otp is required.")
    });
}

const signUpSchema3 = () => {
    const validations = getValidations();
    return yup.object().shape({
        password: validations.password.required("Password is required."),
        confirmPassword: validations.confirmpassword.required("Confirm Password is required."),
    });
}


export {
    signUpSchema1,
    signUpSchema2,
    signUpSchema3,
    signUpSchema4
};
