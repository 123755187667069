export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const bg1 = require('../assets/bg-1.png')
export const check = require('../assets/CHECK.png')
export const location = require('../assets/location-icon.png');
export const userPhoto = require('../assets/11.jpg');
export const DownArrow = require('../assets/down-arrow.svg');
export const UploadIcon = require('../assets/upload-icon.png');
export const closeImage = require("../assets/close.png"); 

