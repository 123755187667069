import React, { Component } from "react";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import { Link, withRouter } from "react-router-dom";
// Customizable Area End
import "./regstyle.css";
import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
import { doctor, recipient, signUpImage } from "./assets";
import { Formik } from "formik";
import ProgressBar from "react-bootstrap/ProgressBar";

class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  onHandlePrivacyPolicy = (): void => {
    // @ts-ignore
    this.props.history.push("/privacy-policy");
  }

  onHandleTermsOfServices = (): void => {
    // @ts-ignore
    this.props.history.push("/terms-of-services");
  }
  render() {
    return (
      <div className="main-bg">
        <div className="container-fluid">
        <div className="row">
          <div className="col-6">
            <div className="hero-title">
              <h4>Covid Passport</h4>
            </div>
            <div className="page-image">
              <img className="hero-image" src={signUpImage} />
            </div>
          </div>
          <div className="col-6">
            <div className="sign-up">
              <span className="stay-safe">STAY SAFE</span>
              <h4 className="sign-up-title">Sign Up</h4>
              <span className="stay-safe mt-1">
                Already have an account ?{" "}
                <Link to="/EmailAccountLoginBlock" className="link-button">
                  Login Here
                </Link>
              </span>
              <div>
                <div>
                  <div className="pg-bar col-8 mt-4 mb-4">
                    <div className="row col-12">
                      <div className="col-6 pg-title">CREATE PROFILE </div>
                      <div className="col-6 pg-title">SET UP ACCOUNT</div>
                    </div>
                    <ProgressBar className="pg-bar2 mt-2 mb-4" now={90} />
                  </div>
                  <div className="col-8 input-fields">
                    <Formik
                      initialValues={{ residentialAddress: "", idProof: "" }}
                      // validationSchema={signUpSchema2}
                      onSubmit={(values) => console.log(values)}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        /* and other goodies */
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="form-group">
                            <input
                              className="text-input form-control"
                              type="text"
                              name="residentialAddress"
                              placeholder="Residential Address"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.residentialAddress}
                            />
                            <span className="error">
                              {errors.residentialAddress &&
                                touched.residentialAddress &&
                                errors.residentialAddress}
                            </span>
                          </div>

                          <div className="form-group">
                            <input
                              className="text-input form-control"
                              type="file"
                              name="idProof"
                              placeholder="Id Proof"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.idProof}
                            />
                            <span className="error">
                              {" "}
                              {errors.idProof &&
                                touched.idProof &&
                                errors.idProof}
                            </span>
                          </div>
                          <div className="form-group checkbox">
                            <input type="checkbox" />
                            <span>
                              {" "}
                              Creating an account means that you agree with our
                              Terms of Services and our Privacy Policy.
                            </span>
                          </div>
                          {/* <button
                            className="form-control button-input"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            SAVE & CONTINUE
                          </button> */}
                          <Link
                          className="form-control button-input"
                          to="/EmailAccountRegistration3"
                          style={{textAlign:'center'}}
                        >
                          SAVE & CONTINUE
                        </Link>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>

              <div className="captcha col-8">
                <span>
                  The site is protected by reCAPTCHA and the google{" "}
                  <span className="link-text" onClick={this.onHandlePrivacyPolicy}>Privacy Policy</span>
                  and <span className="link-text" onClick={this.onHandleTermsOfServices}>Terms</span> of Service apple.
                </span>
              </div>
            </div>
          </div>
        </div>
          </div>
        
      </div>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    // Customizable Area End
  }
}
// @ts-ignore
export default withRouter(EmailAccountRegistration)