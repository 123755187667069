import React, { useEffect, useState } from "react";
import "./consolestyle.css";
import { location } from "./assets";
import { Modal } from "antd";
import { Formik } from "formik";
import { useDropzone } from "react-dropzone";
import { openNotification, openErrorNotification } from "./Notification.web";
export const configJSON = require("./config");
import { closeImage, arrow, UploadIcon } from "./assets";
import Navigation from "./Navigation.web";
// @ts-ignore
import isEmpty from "lodash/isEmpty";
// @ts-ignore
import map from "lodash/map";
const schema = require("./validationSchema/signupSchema.web");

export const ClinicAPI = require("./ClinicService.web");

export default function ClinicConsole() {
  const [vaccineUrl, setVaccineUrl] = useState<any>([]);
  const [idUrl, setIdUrl] = useState<any>([]);
  const [recipientData, setRecipientData] = useState([]);
  const [modal, setModal] = useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState<any>({});
  const [openImage, setOpenImage] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [filterType, setFilterType] = useState("viewAll");
  const [data, setData] = useState([]);

  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [reason, setReason] = useState("");
  const [aprovedID, setAprovedID] = useState();
  const [actionName, setActionName] = useState("");
  const [flag, setFlag] = useState(false)
  const [vaccineName, setVaccineName] = useState("");
  const [vaccines, setVaccines] = useState([]);
  const [files1, setFiles1] = useState([]);
  const [files2, setFiles2] = useState([]);
  const [loader, setLoader] = useState(false)


  console.log(idUrl, vaccineUrl)

  useEffect(() => {
    files1.map((file: any) => {
      getPresignedUrl(file, 'id');
    })
  }, [files1])
  useEffect(() => {
    files2.map((file: any) => {
      getPresignedUrl(file, 'vaccine');
    })
  }, [files2])



  const getPresignedUrl = async (file: any, type: any) => {

    const formData = new FormData();

    // Update the formData object
    formData.append("file", file);

    if (type === 'id') {
      setIdUrl(file)
    } else {
      setVaccineUrl(file)
    }

  };


  useEffect(() => {
    getVaccinesAndClinicNames()
  }, [])

  useEffect(() => {
    if (filterType !== "viewAll") {
      setData(
        recipientData.filter((x: any) => {
          return x.attributes.approval_status === filterType;
        })
      );
    } else {
      setData(recipientData);
    }
  }, [filterType]);

  useEffect(() => {
    setData(recipientData)
  }, [flag])

  useEffect(() => {
    getRecipients();
  }, []);

  useEffect(() => {
    getRecipients();
    setFlag(!flag)
    setFilterType('viewAll')
  }, [modal]);

  const getRecipients = async () => {
    const result = await ClinicAPI.getRecipientDetails();
    if (result.success) {
      setRecipientData(result.data);
      setData(result.data);
    } else {
      console.log("error from create recipient:", result.error);
    }
  };

  const handleShowDialog = (file: any) => {
    setOpenImage(!openImage);
    setImageUrl(file);
  };

  function ImageUploader1() {
    const thumbsContainer = {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginTop: 16,
    };

    const thumb = {
      display: "inline-flex",
      borderRadius: 2,
      border: "1px solid #eaeaea",
      marginBottom: 8,
      marginRight: 8,
      width: 100,
      height: 100,
      padding: 4,
      boxSizing: "border-box",
    };

    const thumbInner = {
      display: "flex",
      minWidth: 0,
      overflow: "hidden",
    };

    const img = {
      display: "block",
      width: "100%",
      height: "100%",
    };

    // const [files, setFiles] = useState([]);
    const [openImage, setOpenImage] = useState(false);
    const [imageUrl1, setImageUrl1] = useState();

    const remove = (file: any) => {
      const newFiles = [...files1];     // make a var for the new array
      newFiles.splice(file, 1);        // remove the file from the array
      setFiles1(newFiles);              // update the state
    };

    const { getRootProps, getInputProps } = useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles: any) => {
        setFiles1(
          acceptedFiles.map((file: any) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

    const thumbs = files1.map((file: any) => (
      <div
        style={{
          display: "inline-flex",
          borderRadius: 2,
          border: "1px solid #eaeaea",
          marginBottom: 8,
          marginRight: 8,
          width: 100,
          height: 100,
          padding: 4,
          boxSizing: "border-box",
        }}
        key={file.name}
      >
        <div style={thumbInner}>
          <img
            onClick={() => handleShowDialog1(file.preview)}
            src={file.preview}
            style={img}
          />
        </div>
        <div>
          <button className="btn btn-outline-secondary" type="button" onClick={() => remove(file)} style={{ zIndex: 100 }} >x</button>
        </div>
      </div>
    ));

    // useEffect(
    //   () => () => {
    //     // Make sure to revoke the data uris to avoid memory leaks
    //     files1.forEach((file: any) => {
    //       const formData = new FormData();

    //       // Update the formData object
    //       formData.append("file", file, file.name);

    //       // setIdUrl((prevArray) => [...prevArray, formData]);

    //       URL.revokeObjectURL(file.preview);
    //     });
    //   },
    //   [files1]
    // );

    const handleShowDialog1 = (file: any) => {
      setOpenImage(!openImage);
      setImageUrl1(file);
    };

    return (
      <section className="image-input-clinic">
        <div className="text-left ml-4 pt-3">
          <span>ID Proof</span>
        </div>
        <div {...getRootProps({ className: "" })}>
          <input name="idProof" {...getInputProps()} className="image-input" />
          {/* <p style={{ paddingTop: "10px" }}>click to select files</p> */}
          <div className="row col-12 ml-3 mt-3" style={{ textAlign: 'center' }}>
            <div className='col-1'>
              <img src={UploadIcon} className="upload-icon" />
            </div>
            <div className='col-6 image-upload-text4'>
              <span>Upload files</span>
            </div>
          </div>
        </div>
        <aside
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginTop: 16,
            paddingLeft: 10,
          }}
        >
          {thumbs}
        </aside>
        {openImage && (
          <dialog className="dialog image-preview-modal" style={{ position: "absolute" }} open>
            <img
              className="close-btn"
              src={closeImage}
              onClick={() => handleShowDialog1("file")}
            />
            <img className="image" src={imageUrl1} alt="Id Proof" />
          </dialog>
        )}
      </section>
    );
  }

  function ImageUploader2() {
    const thumbsContainer = {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginTop: 16,
    };

    const thumb = {
      display: "inline-flex",
      borderRadius: 2,
      border: "1px solid #eaeaea",
      marginBottom: 8,
      marginRight: 8,
      width: 100,
      height: 100,
      padding: 4,
      boxSizing: "border-box",
    };

    const thumbInner = {
      display: "flex",
      minWidth: 0,
      overflow: "hidden",
    };

    const img = {
      display: "block",
      width: "100%",
      height: "100%",
    };

    // const [files, setFiles] = useState([]);
    const [openImage, setOpenImage] = useState(false);
    const [imageUrl2, setImageUrl2] = useState();

    const remove = (file: any) => {
      const newFiles = [...files2];     // make a var for the new array
      newFiles.splice(file, 1);        // remove the file from the array
      setFiles2(newFiles);              // update the state
    };

    const { getRootProps, getInputProps } = useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles: any) => {
        setFiles2(
          acceptedFiles.map((file: any) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

    const thumbs = files2.map((file: any) => (
      <div
        style={{
          display: "inline-flex",
          borderRadius: 2,
          border: "1px solid #eaeaea",
          marginBottom: 8,
          marginRight: 8,
          width: 100,
          height: 100,
          padding: 4,
          boxSizing: "border-box",
        }}
        key={file.name}
      >
        <div style={thumbInner}>
          <img
            onClick={() => handleShowDialog2(file.preview)}
            src={file.preview}
            style={img}
          />
        </div>
        <div>
          <button className="btn btn-outline-secondary" type="button" onClick={() => remove(file)} style={{ zIndex: 100 }} >x</button>
        </div>
      </div>
    ));

    // useEffect(
    //   () => () => {
    //     // Make sure to revoke the data uris to avoid memory leaks
    //     files2.forEach((file: any) => {
    //       const formData = new FormData();

    //       // Update the formData object
    //       formData.append("file", file, file.name);

    //       // setVaccineUrl((prevArray) => [...prevArray, formData]);

    //       URL.revokeObjectURL(file.preview);
    //     });
    //   },
    //   [files2]
    // );

    const handleShowDialog2 = (file: any) => {
      setOpenImage(!openImage);
      setImageUrl2(file);
    };


    return (
      <section className="image-input-clinic">
        <div className="text-left ml-4 pt-3">
          <span>Vaccine Proof</span>
        </div>
        <div {...getRootProps({ className: "" })}>
          <input name="idProof" {...getInputProps()} className="image-input" />
          {/* <p style={{ paddingTop: "10px" }}>click to select files</p> */}
          <div className="row col-12 ml-3 mt-3" style={{ textAlign: 'center' }}>
            <div className='col-1'>
              <img src={UploadIcon} className="upload-icon" />
            </div>
            <div className='col-6 image-upload-text4'>
              <span>Upload files</span>
            </div>
          </div>
        </div>
        <aside
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginTop: 16,
            paddingLeft: 10,
          }}
        >
          {thumbs}
        </aside>
        {openImage && (
          <dialog className="dialog image-preview-modal" style={{ position: "absolute" }} open>
            <img
              className="close-btn"
              src={closeImage}
              onClick={() => handleShowDialog2("file")}
            />
            <img className="image" src={imageUrl2} alt="Id Proof" />
          </dialog>
        )}
      </section>
    );
  }

  const handleCreateRecipient = async (values: any) => {

    if (idUrl.length !== 0 && vaccineUrl.length !== 0 && vaccineName !== "") {
      setLoader(true)
      const result = await ClinicAPI.create_recipient(values, idUrl, vaccineUrl, vaccineName);
      if (result.success) {
        openNotification("User Account Created Successfully", "success");
        setLoader(false)
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        setLoader(false)
        console.log("error from create recipient:", result.error);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      }
    } else {
      setLoader(false)
      if (idUrl.length === 0) {
        openErrorNotification("Please Upload Id Proof", "warning")
      }
      if (vaccineUrl.length === 0) {
        openErrorNotification("Please Upload Vaccine Proof", "warning")
      }
      if (vaccineName === "") {
        openErrorNotification("Please Select Vaccine", "warning")
      }
    }



  };



  const handleViewModal = (value: any) => {
    setSelectedRecipient(value);
    setModal(!modal);
  };

  const handleRejectRequest = async (values: any) => {
    setActionName("reject");
    setReason("")
    setShouldShowModal(true);
    setAprovedID(values);
  };

  const sendToReject = async () => {
    if (isEmpty(reason)) {
      openErrorNotification("Please write a reason before reject", "error");
      return
    };
    setShouldShowModal(false);
    const data = {
      approval_status: "rejected",
      reason: reason,
    };
    const result = await ClinicAPI.approve_reject(aprovedID, data);
    if (result.success) {
      openNotification("Rejected Successfully", "success");
      setModal(!modal);
      setReason("")
    } else {
      console.log("error from create recipient:", result.error);
    }
  };
  const handleApproveRequest = async (values: any) => {
    setActionName("approve");
    setReason("")
    setAprovedID(values);
    setShouldShowModal(true);
  };

  const sendToAprove = async () => {
    if (isEmpty(reason)) {
      openErrorNotification("Please write a reason before aprove", "error");
      return
    };
    setShouldShowModal(false);
    const data = {
      approval_status: "approved",
      reason: reason,
    };

    const result = await ClinicAPI.approve_request(aprovedID, data);
    if (result.success) {
      openNotification("Approved Successfully", "success");
      setModal(!modal);
      setReason('')

    } else {
      console.log("error from create recipient:", result.error);
    }
  };

  const getVaccinesAndClinicNames = async () => {
    const result = await ClinicAPI.getVaccinesAndClinicNames();
    if (result.success) {
      console.log("vaccinationDetails", result);
      setVaccines(result?.vaccines?.data);
      // openNotification("Successfully get all questions", "success");
    } else {
      console.log("error from error :", result.error);
    }
  }

  const onHandleVaccineChange = (event: any) => {
    if (event.target?.value) {
      setVaccineName(event.target?.value);
    }
  }



  return (
    <div>
      <div className="container">
        <Modal
          // title="Basic Modal"
          visible={shouldShowModal}
          onOk={actionName === "approve" ? sendToAprove : sendToReject}
          onCancel={() => setShouldShowModal(false)}
        >
          <div>
            <label>
              {actionName === "approve" ? "Approve Message" : "Reject Message"}
            </label>
            <input
              type="text"
              className="text-input form-control modal-input-text"
              onChange={(event) => setReason(event.target.value)}
              value={reason}
            />
          </div>
        </Modal>
        <Navigation />
        <div className="col-12 row main-clinic-bg">
          <div className="col-5 sub-clinic-bg">
            <p className="clinic-requests mt-2 ml-4">
              {modal && (
                <span onClick={() => setModal(!modal)}>
                  <img src={arrow} />
                </span>
              )}{" "}
              Clinic Requests{" "}
            </p>
            {modal ? (
              <div>
                <table className="table borderless user-details-view">
                  <tr>
                    <td>FULL NAME</td>
                    <td>STATUS</td>
                  </tr>
                  <tr>
                    <th>
                      {selectedRecipient &&
                        selectedRecipient.attributes.recipient.data.attributes
                          .first_name}
                    </th>
                    <th>
                      {selectedRecipient &&
                        selectedRecipient.attributes.approval_status}
                    </th>
                  </tr>
                  <tr>
                    <td>EMAIL</td>
                    <td>CONTACT NUMBER</td>
                  </tr>
                  <tr>
                    <th>
                      {selectedRecipient &&
                        selectedRecipient.attributes.recipient.data.attributes
                          .email}
                    </th>
                    <th>
                      {selectedRecipient &&
                        selectedRecipient.attributes.recipient.data.attributes
                          .full_phone_number}
                    </th>
                  </tr>
                  <tr>
                    <td>RESIDENTIAL ADDRESS </td>
                    <td>CLINIC NAME</td>
                  </tr>
                  <tr>
                    <th>
                      {selectedRecipient &&
                        selectedRecipient.attributes.recipient.data.attributes
                          .residential_address}
                    </th>
                    <th>
                      {selectedRecipient &&
                        selectedRecipient.attributes.clinic.data.attributes
                          .first_name}
                    </th>
                  </tr>
                  <tr>
                    <td>VACCINE NAME</td>
                  </tr>
                  <tr>
                    <th>
                      {selectedRecipient &&
                        selectedRecipient.attributes.vaccine.data.attributes
                          .name}
                    </th>
                  </tr>
                  <tr>
                    <td>ID PROOF</td>
                    <td>VACCINATION PROOF</td>
                  </tr>
                  <tr className='user-proff-tr'>
                    <th>
                      { }
                      <div className="user-proof">
                        <img
                          onClick={() =>
                            handleShowDialog(
                              selectedRecipient.attributes.recipient.data
                                .attributes.image_urls[0] || selectedRecipient.attributes.recipient.data
                                  .attributes.profile_image
                            )
                          }
                          width="173"
                          height="173"
                          src={
                            selectedRecipient.attributes.recipient.data
                              .attributes.image_urls[0] || selectedRecipient.attributes.recipient.data
                                .attributes.profile_image
                          }
                        />
                      </div>
                    </th>
                    <th>
                      <div className="user-proof">
                        <img
                          onClick={() =>
                            handleShowDialog(
                              selectedRecipient.attributes?.vaccine_proof[0] || selectedRecipient.attributes?.certificate?.data?.attributes?.certificate
                            )
                          }
                          width="173"
                          height="173"
                          src={selectedRecipient.attributes?.vaccine_proof[0] || selectedRecipient.attributes?.certificate?.data?.attributes?.certificate}
                        />
                      </div>
                    </th>
                  </tr>
                </table>

                {openImage && (
                  <dialog
                    className="dialog"
                    style={{ position: "absolute" }}
                    open
                  >
                    <img
                      className="close-btn"
                      src={closeImage}
                      onClick={() => handleShowDialog("file")}
                    />
                    <img className="image" src={imageUrl} alt="Id Proof" />
                  </dialog>
                )}
                {
                  selectedRecipient?.attributes?.approval_status !== "approved" &&
                  <div className="col-12 row">
                    <div className="col-6">
                      <button
                        onClick={() => {
                          handleApproveRequest(
                            selectedRecipient.attributes.recipient.data.id
                          )
                        }

                        }
                        className="form-control button-input"
                        type="submit"
                      >
                        APPROVE REQUEST
                      </button>
                    </div>
                    <div className="col-6">
                      <button
                        onClick={() => {
                          handleRejectRequest(
                            selectedRecipient.attributes.recipient.data.id
                          )
                        }

                        }
                        className="form-control button-input"
                        type="submit"
                      >
                        REJECT REQUEST
                      </button>
                    </div>
                  </div>
                }
              </div>
            ) : (
              <div>
                <div className="col-12 row">
                  <div className="col-3 status-type">
                    <input
                      className="filter-type pending"
                      name="filterType"
                      value="pending"
                      type="radio"
                      onClick={() => setFilterType("submitted for clinic review")}
                    />
                    Pending
                  </div>
                  <div className="col-3 status-type">
                    <input
                      className="filter-type approved"
                      name="filterType"
                      value="approved"
                      type="radio"
                      onClick={() => setFilterType("approved")}
                    />
                    Approved
                  </div>
                  <div className="col-3 status-type">
                    <input
                      className="filter-type rejected"
                      name="filterType"
                      value="rejected"
                      type="radio"
                      onClick={() => setFilterType("rejected")}
                    />
                    Rejected
                  </div>
                  <div className="col-3 status-type">
                    <input
                      className="filter-type view-all"
                      name="filterType"
                      value="viewAll"
                      type="radio"
                      checked={filterType === "viewAll" ? true : false}
                      onClick={() => setFilterType("viewAll")}
                    />
                    View All
                  </div>
                </div>
                <div className="mt-2" style={{ height: '637px', overflow: 'auto', marginBottom: '10px' }}>
                  <table className="table table-striped table-list-data">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Contact</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((x: any) => {
                        return (
                          <tr>
                            <td>
                              <div
                                className="recipient-dot"
                                style={
                                  x.attributes.approval_status === "approved"
                                    ? { backgroundColor: "#27b49a" }
                                    : x.attributes.approval_status ===
                                      "rejected"
                                      ? { backgroundColor: "#ea4335" }
                                      : { backgroundColor: "#f5a281" }
                                }
                              />
                              <span className="recipient-name">
                                {
                                  x?.attributes?.recipient.data?.attributes
                                    .first_name
                                }
                              </span>
                            </td>
                            <td>
                              {x?.attributes?.recipient.data?.attributes?.email}
                            </td>
                            <td>
                              {
                                x?.attributes?.recipient?.data?.attributes?.full_phone_number || x?.attributes?.recipient?.data?.attributes?.phone_number
                              }
                            </td>
                            <td>
                              <div className="dropdown action-btn">
                                <button
                                  className="btn drp-btn"
                                  type="button"
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <ul>
                                    <li className="list-item" />
                                    <li className="list-item" />
                                    <li className="list-item" />
                                  </ul>
                                </button>
                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <button
                                    className="dropdown-item"
                                    onClick={() => handleViewModal(x)}
                                  >
                                    View Details
                                  </button>
                                  <button
                                    className="dropdown-item"
                                    onClick={() => handleViewModal(x)}
                                  >
                                    Edit Request
                                  </button>
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
          <div className="col-5 sub-clinic-bg">
            <p className="add-recipient mt-4 mb-3 ml-4"> Add a recipient</p>
            <div className="col-10 ml-4">
              <Formik
                initialValues={{ email: "", fullName: "", contactNumber: "" }}
                validationSchema={schema.signUpSchema1}

                onSubmit={(values) => {
                  handleCreateRecipient(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  /* and other goodies */
                }) => (
                  <form className="form-group" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input
                        className="text-input2 form-control"
                        type="text"
                        name="fullName"
                        placeholder="Full Name"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.fullName}
                      />
                      <span className="error">
                        {errors.fullName && touched.fullName && errors.fullName}
                      </span>
                    </div>

                    <div className="form-group">
                      <input
                        className="text-input2 form-control"
                        type="email"
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                        // onBlur={handleBlur}
                        value={values.email}
                      />
                      <span className="error">
                        {" "}
                        {errors.email && touched.email && errors.email}
                      </span>
                    </div>
                    <div className="form-group">
                      <input
                        className="text-input2 form-control"
                        type="number"
                        name="contactNumber"
                        placeholder="Contact Number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.contactNumber}
                      />
                      <span className="error">
                        {errors.contactNumber &&
                          touched.contactNumber &&
                          errors.contactNumber}
                      </span>
                    </div>
                    <div className="form-group">
                      <select
                        style={{ height: '50px' }}
                        className="form-control text-input2"
                        onChange={onHandleVaccineChange}
                      >
                        <option>Select Vaccine</option>
                        {map(vaccines, (vaccine: any) => {
                          return (
                            <option
                              value={vaccine?.id}
                            >
                              {vaccine?.attributes?.name.toUpperCase()}
                            </option>
                          )
                        })}
                      </select>
                    </div>
                    <div className="form-group container col-12 row upload-div" style={{
                      marginLeft: '-5%',
                      padding: '0px'
                    }}>
                      <div className="upload-proof col-6">
                        {/* <span>Vaccine Proof</span> */}
                        <ImageUploader1 />
                      </div>

                      <div className="upload-proof col-6">
                        {/* <span>Vaccine Proof</span> */}
                        <ImageUploader2 />
                      </div>
                    </div>
                    <button
                      className="form-control button-input"
                      type="submit"
                      disabled={loader}
                    >
                      {loader ? "Loading...." : "REGISTER"}
                    </button>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
