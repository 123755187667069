import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom"
import { bg1 } from "./assets";
import { openNotification, openErrorNotification } from "./Notification.web";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import './regmstyle.css'

// @ts-ignore
import map from "lodash/map";
// @ts-ignore
import chunk from "lodash/chunk";
// @ts-ignore
import cloneDeep from "lodash/cloneDeep";
// @ts-ignore
import isEmpty from "lodash/isEmpty";
// @ts-ignore
import find from "lodash/find";
// @ts-ignore
import reject from "lodash/reject";
// @ts-ignore
import includes from "lodash/includes";
// @ts-ignore
import remove from "lodash/remove";
import Navigation from "../../AdminConsole/src/Navigation.web";
const userGetAllQuestions = require("./UserSurveyFormService.web");

function Survey(props: any) {
  const [surveyDetails, setsurveyDetails] = useState([]);
  const [serveyAnswer, setServeyAnswer] = useState([]);
  const [writtenAnswer, setWrittenAnswer] = useState([]);
  const [isRegisteredByClinic, setIsRegisteredByClinic] = useState();
  const [otherSelectedOptions, setOtherSelectedOptions] = useState([]);
  const [touch, setTouch] = useState(false);

  const [name, setName] = useState(window.localStorage.getItem('Name'))

  const getAllQuestions = async () => {
    const result = await userGetAllQuestions.userGetAllQuestions();
    if (result.success) {
      console.log("surveyDetails", result);
      const arr = result?.questions?.survey;
      setIsRegisteredByClinic(result?.is_registered_by_clinic)
      setsurveyDetails(arr);
      // openNotification("Successfully get all questions", "success");
    } else {
      console.log("error from error :", result.error);
    }
  };
  useEffect(() => {
    getAllQuestions();
  }, []);

  const onHandleChangeQuestionAnswer = (questionID: any, answerID: any, typeOfQuestion: any) => {
    let cloneServeyAnswer = cloneDeep(serveyAnswer);

    let obj = {
      survey_question_id: questionID,
      survey_option_ids: [answerID],
      answer: "",
    }

    if (isEmpty(cloneServeyAnswer)) {
      cloneServeyAnswer.push(obj);
      setServeyAnswer(cloneServeyAnswer);
    } else if (!isEmpty(cloneServeyAnswer) && typeOfQuestion === "single select") {
      if (!isEmpty(find(cloneServeyAnswer, { survey_question_id: questionID }))) {
        cloneServeyAnswer = reject(cloneServeyAnswer, { survey_question_id: questionID })
        cloneServeyAnswer.push(obj);
        setServeyAnswer(cloneServeyAnswer);
      } else {
        cloneServeyAnswer.push(obj);
        setServeyAnswer(cloneServeyAnswer);
      }
    } else if (!isEmpty(cloneServeyAnswer) && typeOfQuestion === "multi select") {
      // record not exits
      if (isEmpty(find(cloneServeyAnswer, { survey_question_id: questionID }))) {
        cloneServeyAnswer.push(obj);
        setServeyAnswer(cloneServeyAnswer);
      } else {
        // record exits
        map(cloneServeyAnswer, (serveyanswer: any) => {
          if (includes(serveyanswer.survey_option_ids, answerID)) {
            // if id is same
            serveyanswer.survey_option_ids = remove(serveyanswer.survey_option_ids, function (n: any) { return n !== answerID })
            // serveyanswserveyanswer.survey_option_idser.survey_option_ids = reject(serveyanswer.survey_option_ids, answerID)
          } else if (serveyanswer.survey_question_id === questionID) {
            // if id is not same
            serveyanswer.survey_option_ids = [...serveyanswer.survey_option_ids, answerID]
          }
        })
        setServeyAnswer(cloneServeyAnswer);
      }
    }
  }

  const onHandleSubmitQuestions = async () => {
    if (isEmpty(serveyAnswer)) return;
    if (getDisabled()) {
      openErrorNotification("Please select all answer", "error");
    }
    const clonedServerAnswer = cloneDeep(serveyAnswer);

    // if user select other option
    map(writtenAnswer, (wanswer: any) => {
      const findRecord = find(clonedServerAnswer, { survey_question_id: wanswer?.survey_question_id });
      if (isEmpty(findRecord)) {
        let obj = {
          survey_question_id: wanswer?.survey_question_id,
          survey_option_ids: [],
          answer: wanswer?.answer,
        }
        clonedServerAnswer.push(obj);
      }
    })

    map(clonedServerAnswer, (servey: any) => {
      const selectedRecord = find(writtenAnswer, { survey_question_id: servey.survey_question_id });
      if (selectedRecord) {
        servey.answer = selectedRecord.answer
      }
    })

    const obj = {
      data: {
        attributes: {
          survey_answers: clonedServerAnswer
        },
      },
    };

    const result = await userGetAllQuestions.submitQuestionsAnswers(obj);

    if (result.success) {
      if (isRegisteredByClinic) {
        props.history.push("/SurveySubmission")
      } else {
        props.history.push("/ClinicSurveyScreen")
      }
    } else {
      console.error("error from error :", result.error);
    }
  };

  const onHandleChangeStepper = (route: any) => {
    props.history.push(route)
  }

  const onHandleChangeAnswer = (value: any, questionID: any,) => {
    let cloneWrittenAnswer = cloneDeep(writtenAnswer);

    let obj = {
      survey_question_id: questionID,
      answer: value,
    }

    if (isEmpty(cloneWrittenAnswer)) {
      cloneWrittenAnswer.push(obj);
      setWrittenAnswer(cloneWrittenAnswer);
    } else {
      if (find(cloneWrittenAnswer, { survey_question_id: questionID })) {
        // record exits
        cloneWrittenAnswer = reject(cloneWrittenAnswer, { survey_question_id: questionID })
        cloneWrittenAnswer.push(obj)
        setWrittenAnswer(cloneWrittenAnswer);
      } else {
        cloneWrittenAnswer.push(obj)
        setWrittenAnswer(cloneWrittenAnswer);
      }
    }
  }

  const onHandleOtherSelection = (event: any, ID: any): void => {
    const { checked } = event.target;
    console.log(event)
    if (checked) {
      // @ts-ignore
      setOtherSelectedOptions([...otherSelectedOptions, ID])
    } else {
      remove(otherSelectedOptions, function (record: string) {
        return record === ID
      })
      // @ts-ignore
      setOtherSelectedOptions([...otherSelectedOptions])
    }

    // Remove record if present in asnwer array.
    const updatedRecord = reject(serveyAnswer, { survey_question_id: ID })
    setServeyAnswer(updatedRecord)
  }

  const uncheckOtherOption = (ID: string): void => {
    remove(otherSelectedOptions, function (record: string) {
      return record === ID
    })
    setOtherSelectedOptions(otherSelectedOptions);
  }

  const IsRecordPresent = (optionID: string): boolean => {
    let present = false;
    map(serveyAnswer, (answer: any) => {
      if (includes(answer.survey_option_ids, optionID)) {
        present = true;
      }
    })
    return present;
  }

  const getDisabled = (): boolean => {
    return (surveyDetails.length !== serveyAnswer.length) && (surveyDetails.length !== (serveyAnswer.length + writtenAnswer.length))
  }
  return (
    <div className="container">
      <div className="main-wrapper container">
        <div className="navigation">
          <Navigation />
        </div>
        <div className="survey-main">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="survey-form">
                  <div className="repeated-section">
                    <div className="vaccination-detail">
                      <p className="user-name">
                        Hello, <span>{name}</span>
                      </p>
                      <h3>What do you feel about the Covid-19 Vaccine?</h3>
                      <p className="covide-infomation">
                        Please note that your inputs will supplement the efforts being
                        taken.
                      </p>
                      <img src={bg1} className="background-image" />
                    </div>
                  </div>
                  <div className="flex-between">
                    <div className="give-detail-steps">
                      {
                        map(surveyDetails, (surveyDetail: any, index: any) => {
                          if (surveyDetail?.data?.attributes?.option_type === "multi select") {
                            return (
                              <div className="questions">
                                <p className="Raleway">
                                  <span>Q {index + 4}. {surveyDetail.data?.attributes?.question}</span>
                                </p>
                                <div className="custom-checkbox d-flex">
                                  {map(chunk(surveyDetail?.data?.attributes?.survey_options?.data, 3), (options: any, index: any) => {
                                    return (
                                      <ul className="unstyled centered mr-5">
                                        {
                                          map(options, (option: any) => {
                                            return (
                                              <li>
                                                <input
                                                  className="styled-checkbox"
                                                  id={option?.id}
                                                  type="checkbox"
                                                  value={option?.attributes?.name}
                                                  onClick={(event) => {
                                                    if (option?.attributes?.name.toLowerCase() === "other") {
                                                      onHandleOtherSelection(event, surveyDetail?.data?.id)
                                                    } else {
                                                      setTouch(true);
                                                      uncheckOtherOption(surveyDetail?.data?.id)
                                                      onHandleChangeQuestionAnswer(surveyDetail?.data?.id, option?.id, surveyDetail?.data?.attributes?.option_type)
                                                    }
                                                  }}
                                                  // checked={!includes(otherSelectedOptions, surveyDetail?.data?.id) && touch}
                                                  checked={option?.attributes?.name.toLowerCase() === "other" ? includes(otherSelectedOptions, surveyDetail?.data?.id) : !includes(otherSelectedOptions, surveyDetail?.data?.id) && touch && IsRecordPresent(option?.id)}
                                                />
                                                <label htmlFor={option?.id}>
                                                  {option?.attributes?.name}
                                                </label>
                                              </li>
                                            )
                                          })
                                        }
                                      </ul>
                                    )
                                  })}
                                  {/* <ul>
                        <li className="open-extra-input">
                          <input
                             className="styled-checkbox"
                            //  id={option?.id}
                             id={`Other-${index}`}
                             type="checkbox"
                             // value={option?.attributes?.name}
                             // onClick={() => onHandleChangeQuestionAnswer(personalDetail?.data?.id, option?.id, personalDetail?.data?.attributes?.option_type)}
                             checked={includes(otherSelectedOptions, surveyDetail?.data?.id)}
                              
                             onClick={(event) => onHandleOtherSelection(event, surveyDetail?.data?.id)}
                          />
                          <label htmlFor={`Other-${index}`}>
                             Other
                          </label>
                      </li>
                    </ul> */}
                                </div>
                                {includes(otherSelectedOptions, surveyDetail?.data?.id) && <div className="open-extra-input2">
                                  <input
                                    type="text"
                                    placeholder="Type in here"
                                    style={{ height: '44px' }}
                                    onChange={(event) => onHandleChangeAnswer(event.target.value, surveyDetail?.data?.id)}
                                  />
                                  <div className="ok-view">
                                    <div className="ok-box">
                                      OK
                                    </div>
                                  </div>
                                </div>}
                              </div>
                            )
                          } else {
                            return (
                              <div className="questions">
                                <p className="Raleway">
                                  <span>Q {index + 4}. {surveyDetail.data?.attributes?.question}</span>

                                </p>
                                {
                                  map(surveyDetail?.data?.attributes?.survey_options?.data, (option: any) => {
                                    return (
                                      <div className="answer-wrapper Raleway">
                                        <input
                                          type="radio"
                                          name={`select-${surveyDetail.data?.attributes?.id}`}
                                          id={option?.id}
                                          onClick={() => onHandleChangeQuestionAnswer(surveyDetail?.data?.id, option?.id, surveyDetail?.data?.attributes?.option_type)}
                                        />
                                        <label htmlFor={option?.id} className="option option-1">
                                          <span> {(option?.attributes?.name).toUpperCase()}</span>
                                        </label>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            )
                          }
                        })
                      }
                    </div>
                    {/* Form Stepper - add class "active"*/}
                    <div className="form-steps step2">
                      <div
                        className="step active"
                      // onClick={() => onHandleChangeStepper("/SurveyPersonalDetails")}
                      >{/* add class "active"*/}
                        <span className="number">1</span> {/* add onclick for routing */}
                        <p>Personal Details</p>
                        <div className="step-border"><div className="active-border"></div></div>
                      </div>
                      <div className="step active">
                        <span className="number">2</span>
                        <p>Survey</p>
                        <div className="step-border"><div className="active-border"></div></div>
                      </div>
                      <div
                        className="step"
                      // onClick={() => onHandleChangeStepper("/ClinicSurveyScreen")}
                      >
                        <span className="number">3</span>
                        <p>Vaccination</p>
                      </div>
                    </div>
                  </div>
                  <div className="save-continue">
                    <button
                      // className="button button-primary"
                      className={(surveyDetails.length !== serveyAnswer.length) ? "button button-primary btn-gry" : "button button-primary"}
                      disabled={getDisabled()}
                      onClick={onHandleSubmitQuestions}
                    >
                      Submit & continue
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Survey)
