import React, { useEffect, useState, useRef } from "react";
import {
  doctor,
  recipient,
  signUpImage,
  closeImage,
  UploadIcon,
} from "./assets";
import { Radio } from "antd";

import { Link, Redirect, withRouter } from "react-router-dom";
import { Formik, Field } from "formik";
import ProgressBar from "react-bootstrap/ProgressBar";
import { openNotification } from "./Notification.web";
import { useDropzone } from "react-dropzone";
import Geocode from "react-geocode";
// @ts-ignore
import isEmpty from "lodash/isEmpty";
import CountryCodeSelectorTable from "../../country-code-selector/src/CountryCodeSelectorTable";
// import "./regstyle.css";
import OtpInput from 'react-otp-input';
export const configJSON = require("./config");
import { imgPasswordInVisible, imgPasswordVisible, place } from "./assets";


const schema = require("./validationSchema/signupSchema.web");

export const userSignUp = require("./EmailAccountRegistrationService.web");

function EmailAccountRegistration(props: any) {
  const [data, setData] = useState({});
  const [stepCount, setStepCount] = useState(0);
  const [userId, setUserId] = useState();
  const [redirect, setRedirect] = useState(false);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [address, setAddress] = useState("");
  const [userType, setUserType] = useState("recipient");
  const [check, setCheck] = useState(false);
  const [publicUrl, setPublicUrl] = useState<any[]>([]);
  const [loader, setLoader] = useState(false);
  const [tcChecked, setTcChecked] = useState(false);
  const [error, setError] = useState({ address: "", checkbox: "", file: "" });

  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("")

  const [imageUpload, setImageUpload] = useState(false)
  // // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
  // Geocode.setApiKey(configJSON.googleAPI);

  // // set response language. Defaults to english.
  // Geocode.setLanguage("en");

  // // set response region. Its optional.
  // // A Geocoding request with region=es (Spain) will return the Spanish city.
  // Geocode.setRegion("es");

  // // set location_type filter . Its optional.
  // // google geocoder returns more that one address for given lat/lng.
  // // In some case we need one address as response for which google itself provides a location_type filter.
  // // So we can easily parse the result for fetching address components
  // // ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
  // // And according to the below google docs in description, ROOFTOP param returns the most accurate result.
  // // Geocode.setLocationType("ROOFTOP");

  // // Enable or disable logs. Its optional.
  // Geocode.enableDebug();

  // // Get address from latitude & longitude.
  // Geocode.fromLatLng(latitude, longitude).then(
  //   (response: any) => {
  //     const address = response.results[0].formatted_address;
  //     console.log(response);
  //     setAddress(address);
  //   },
  //   (error: any) => {
  //     console.error(error);
  //   }
  // );



  // const onHandleChangeFirstValue = (event: any) => {
  //   if (event.target?.value?.length < 2) {
  //     setFirstValue(event.target.value);
  //   }
  // }

  // const onHandleChangeSecondValue = (event: any) => {
  //   if (event.target?.value?.length < 2) {
  //     setSecondValue(event.target.value);
  //   }
  // }
  // const onHandleChangeThirdValue = (event: any) => {
  //   if (event.target?.value?.length < 2) {
  //     setThirdValue(event.target.value);
  //   }
  // }
  // const onHandleChangeFourthValue = (event: any) => {
  //   if (event.target?.value?.length < 2) {
  //     setFourthValue(event.target.value);
  //   }
  // }

  function ImageUploader() {
    const thumbsContainer = {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginTop: 16,
    };

    const thumb = {
      display: "inline-flex",
      borderRadius: 2,
      border: "1px solid #eaeaea",
      marginBottom: 8,
      marginRight: 8,
      width: 100,
      height: 100,
      padding: 4,
      boxSizing: "border-box",
    };

    const thumbInner = {
      display: "flex",
      minWidth: 0,
      overflow: "hidden",
    };

    const img = {
      display: "block",
      width: "100%",
      height: "100%",
    };

    const [files, setFiles] = useState([]);
    const [openImage, setOpenImage] = useState(false);
    const [imageUrl, setImageUrl] = useState();

    const { getRootProps, getInputProps } = useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles: any) => {
        setFiles(
          acceptedFiles.map((file: any) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

    const remove = (file: any) => {
      const newFiles = [...publicUrl];     // make a var for the new array
      newFiles.splice(file, 1);        // remove the file from the array
      setPublicUrl(newFiles);              // update the state
    };

    const thumbs = files.map((file: any) => (
      <div
        style={{
          display: "inline-flex",
          borderRadius: 2,
          border: "1px solid #eaeaea",
          marginBottom: 8,
          marginRight: 8,
          width: 100,
          height: 100,
          padding: 4,
          boxSizing: "border-box",
        }}
        key={file.name}
      >
        <div style={thumbInner}>
          <img
            onClick={() => handleShowDialog(file.preview)}
            src={file.preview}
            style={img}
          />
        </div>
      </div >
    ));



    const thumbs1 = publicUrl.map((file: any) => (
      <div
        style={{
          display: "inline-flex",
          borderRadius: 2,
          border: "1px solid #eaeaea",
          marginBottom: 8,
          marginRight: 8,
          width: 100,
          height: 100,
          padding: 4,
          boxSizing: "border-box",
        }}
        key={file.name}
      >
        <div style={thumbInner}>
          <img onClick={() => handleShowDialog(file)} src={file} style={img} />
        </div>
        <div>
          <button className="btn btn-outline-secondary" type="button" onClick={() => remove(file)} style={{ zIndex: 100 }} >x</button>
        </div>
      </div>

    ));

    useEffect(
      () => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach((file: any) => {
          URL.revokeObjectURL(file.preview);
        });
      },
      [files]
    );

    useEffect(() => {
      files.map((file: any) => {
        getPresignedUrl(file);
      });
    }, [files]);

    const getPresignedUrl = async (file: any) => {
      const formData = new FormData();

      // Update the formData object
      formData.append("file", file, file.name);

      const result = await userSignUp.fileUpload(formData);
      if (result.success) {
        // openNotification("File Uploaded", "success");
        setPublicUrl((prevArray) => [...prevArray, result.public_url]);
      } else {
        console.log("error while uploading file:", result.error);
      }
    };

    const handleShowDialog = (file: any) => {
      setOpenImage(!openImage);
      setImageUrl(file);
    };

    return (
      <>
        <section className="image-input-register">
          <div className="text-left ml-4 pt-3">ID Proof</div>
          {
            publicUrl.length < 2 ?
              <div {...getRootProps({ className: "" })}>
                <input name="idProof" {...getInputProps()} className="image-input" />
                <div className="row col-12 image-upload-text mt-3">
                  <div className='col-1'>
                    <img src={UploadIcon} className="upload-icon" />
                  </div>
                  <div className='col-6 image-upload-text2'>
                    <span>Upload files</span>
                  </div>
                </div>
              </div>
              :
              <div className='col-6 image-upload-text3'>
                <span style={{ textAlign: 'center' }}>User should able to upload maximum 2 Id proofs</span>
              </div>
          }



          <aside
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              marginTop: 16,
              paddingLeft: 10,
            }}
          >
            {thumbs}
            {thumbs1}
          </aside>
          {openImage && (
            <dialog className="image-preview-modal dialog" style={{ position: "absolute" }} open>
              <img
                className="close-btn"
                src={closeImage}
                onClick={() => handleShowDialog("file")}
              />
              <img className="image" src={imageUrl} alt="Id Proof" />
            </dialog>
          )}
        </section>

      </>
    );
  }

  const hanldeResendOtp = async () => {
    const res = await userSignUp.resendOtp(email);
    if (res.success) {
      openNotification(`Otp Sent to on your ${email}`, "success");
      // setRedirect(true);
    }
  }

  const handleSignUp = async (values: any) => {
    setLoader(true);
    const result = await userSignUp.userSignUp(
      data,
      values,
      userType,
      publicUrl
    );
    if (result.success) {
      window.localStorage.setItem(
        "token",
        result && result.meta && result.meta.token
      );
      setLoader(false);
      openNotification(
        "User Account Created Successfully, Please Verify Otp",
        "success"
      );
      setUserId(result.data.id);
      setEmail(result.data.attributes.email)
      setStepCount(3);
    } else {
      setLoader(false);
      console.log("error from userSignUp:", result.error);
    }
    setLoader(false);
  };

  const handleOtp = async (e: any) => {
    e.preventDefault();
    let values;
    if (otp.length !== 4) return;
    values = {
      otp: parseInt(otp),
    };
    // const res = await userSignUp.otpVerification(values, userId);
    const res = await userSignUp.otpVerification(values, userId);
    if (res.success) {
      openNotification("Otp Verified Successfully, Please login", "success");
      setRedirect(true);
    }
  };

  const handleStep1 = (values: any) => {
    if (userType === "") {
      setCheck(true);
    } else {
      setStepCount(1);
      setData({ ...data, step1: values });
    }
  };

  // console.log("data", data)

  const handleStep2 = (values: any) => {
    if (tcChecked && address !== "" && publicUrl.length > 0) {
      setStepCount(2);
    }
    if (address === "") {
      setError({ file: "", checkbox: "", address: "Please Select Address" });
    }

    if (publicUrl.length < 0 || !imageUpload) {
      setError({ address: "", checkbox: "", file: "Please Select File" });
    }
    if (!tcChecked) {
      setError({
        address: "",
        file: "",
        checkbox: "Please Agree Terms of Services",
      });
    }

    setData({ ...data, step2: values });
  };

  const getCoordinates = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  async function showPosition(position: any) {
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);

    const res = await userSignUp.getAddress(
      position.coords.latitude,
      position.coords.longitude
    );
    if (res.success) {
      setAddress(res.locality);
      setError({ file: "", checkbox: "", address: "" });

    }
  }

  const handleUserType = (e: any) => {
    setUserType(e.target.value);
  };

  const handleCheckBox = (e: any) => {
    setTcChecked(e.target.checked);
  };

  const onHandlePrivacyPolicy = (): void => {
    props.history.push("/privacy-policy");
  }

  const onHandleTermsOfServices = (): void => {
    props.history.push("/terms-of-services");
  }

  function Step1() {
    return (
      <div className="row">
        <div className="pg-bar col-lg-8 mt-4 ">
          <div className="row col-12">
            <div className="col-6 pg-title">CREATE PROFILE </div>
            <div className="col-6 pg-title">SET UP ACCOUNT</div>
          </div>
          <ProgressBar className="pg-bar2 mt-2 mb-4" now={50} />
        </div>
        <div className="for-customers">
          <div className="row mt-2 selection flex-nowrap">
            <div className=" recipient-content col-lg-6 col-6">
              <div className="name">
                {/* <input
                name="recipient"
                onChange={(e) => handleUserType(e)}
                className="radio-btn"
                type="radio"
                value="recipient"
                checked={userType === "recipient"}
                />
                
                <span>Recipient</span> */}
                <Radio
                  name="recipient"
                  onChange={(e) => {
                    handleUserType(e)
                  }}
                  // className="radio-btn"
                  value="recipient"
                  className="radio-item"
                  checked={userType === "recipient"}
                >
                  Recipient
                </Radio>
              </div>
              <div className="avtar">
                <img className="small-avatar" src={recipient} alt="recipient" />
              </div>
            </div>
            <div className="recipient-content col-lg-6 col-6">
              <div className="name">
                {/* <input
                name="recipient"
                onChange={(e) => handleUserType(e)}
                className="radio-btn"
                type="radio"
                value="clinic"
                checked={userType === "clinic"}
              />
                <span className="">Clinic</span> */}
                <Radio
                  name="recipient"
                  onChange={(e) => {
                    handleUserType(e)
                  }}
                  // className="radio-btn"
                  value="clinic"
                  checked={userType === "clinic"}
                >
                  Clinic
                </Radio>
              </div>
              <div className="avtar">
                <img className="small-avatar" src={doctor} alt="recipient" />
              </div>
            </div>

          </div>
        </div>

        <div className="new-divs">
          <div className="col-md-12">
            <div className="mt-2 selection-text" style={{ color: "red" }}>
              {check && userType === "" ? "Please Select User Type" : ""}
            </div>
            <div className="mt-2 selection-text">
              <span>Choose the one you resemble</span>
            </div>
          </div>
        </div>



        <div className="col-lg-8 col-md-10 input-fields">
          <Formik
            initialValues={{ email: "", fullName: "", contactNumber: "" }}
            validationSchema={schema.signUpSchema1}
            onSubmit={(values) => {
              handleStep1(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    className="text-input form-control"
                    type="text"
                    name="fullName"
                    placeholder={userType === "recipient" ? "Full Name" : "Clinic Name"}
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    value={values.fullName}
                  />
                  <span className="error">
                    {errors.fullName && touched.fullName && errors.fullName}
                  </span>
                </div>

                <div className="form-group">
                  <input
                    className="text-input form-control"
                    type="email"
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    value={values.email}
                  />
                  <span className="error">
                    {" "}
                    {errors.email && touched.email && errors.email}
                  </span>
                </div>
                <div className="form-group">
                  <input
                    className="text-input form-control"
                    type="number"
                    name="contactNumber"
                    placeholder="Contact Number"
                    onChange={handleChange}
                    // onBlur={handleBlur}
                    value={values.contactNumber}
                  />
                  <span className="error">
                    {errors.contactNumber &&
                      touched.contactNumber &&
                      errors.contactNumber}
                  </span>
                </div>
                <button
                  className="form-control button-input"
                  type="submit"
                  disabled={isSubmitting}
                >
                  SAVE & CONTINUE
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }

  function Step2() {
    return (
      <div className="row">
        <div className="pg-bar col-lg-8  mt-4 ">
          <div className="row col-12">
            <div className="col-6 pg-title">CREATE PROFILE </div>
            <div className="col-6 pg-title">SET UP ACCOUNT</div>
          </div>
          <ProgressBar className="pg-bar2 mt-2 mb-4" now={90} />
        </div>
        <div className="col-8 input-fields2">
          <Formik
            initialValues={{ residentialAddress: address || "", idProof: "" }}
            // validationSchema={schema.signUpSchema2}
            onSubmit={(values) => handleStep2(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    className="text-input form-control"
                    type="text"
                    name="residentialAddress"
                    placeholder={
                      userType === "clinic"
                        ? "Clinic Address"
                        : "Residential Address"
                    }
                    onChange={handleChange}
                    // onFocus={() => getCoordinates()}
                    value={values.residentialAddress}
                  />
                  <div className="locateme-view flex">
                    <div onClick={() => getCoordinates()}>
                      <img width="50%" src={place} alt="view" />
                    </div>
                    <div style={{ marginLeft: '-16%' }} onClick={() => getCoordinates()}>
                      Locate me
                    </div>
                  </div>
                </div>
                <span className="error ml-4">{error.address}</span>
                <div className=""
                  style={{ marginTop: '0px' }}
                >
                  <ImageUploader />
                  <span className="file-error">{error.file}</span>
                </div>
                <div className="form-group checkbox"
                  style={{ marginTop: '0px' }}
                >
                  <input
                    checked={tcChecked}
                    type="checkbox"
                    onChange={(e) => handleCheckBox(e)}
                  />
                  <span>
                    {" "}
                    Creating an account means that you agree with our Terms of
                    Services and our Privacy Policy.
                  </span>
                </div>
                <span className="error ml-4">{error.checkbox}</span>
                <button
                  style={{ marginTop: '0px' }}
                  className="form-control button-input"
                  type="submit"
                  disabled={isSubmitting}
                >
                  SAVE & CONTINUE
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }

  function Step3() {

    const [passwordView, setPasswordView] = useState(false)
    const [rePasswordView, setRePasswordView] = useState(false)

    return (
      <div>
        <div className="col-8 input-fields">
          <Formik
            initialValues={{ password: "", confirmPassword: "" }}
            validationSchema={schema.signUpSchema3}
            onSubmit={(values) => handleSignUp(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    className="form-control text-input"
                    type={!passwordView ? "password" : "text"}
                    name="password"
                    placeholder="Password"
                    onChange={handleChange}
                  // onBlur={handleBlur}
                  // value={values.password}
                  />
                  <div className="password-view1">
                    {!passwordView ? <img width="80%" src={imgPasswordVisible} alt="view" onClick={() => setPasswordView(!passwordView)} /> : <img width="80%" src={imgPasswordInVisible} onClick={() => setPasswordView(!passwordView)} alt="view" />}
                  </div>
                  <div className="error-parent">
                    <span className="custom-error">
                      {errors.password && touched.password && errors.password}
                    </span>
                  </div>
                </div>

                <div className="form-group">
                  <input
                    className="form-control text-input"
                    type={!rePasswordView ? "password" : "text"}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    onChange={handleChange}
                  // onBlur={handleBlur}
                  // value={values.confirmPassword}
                  />
                  <div className="password-view1">
                    {!rePasswordView ? <img width="80%" src={imgPasswordVisible} alt="view" onClick={() => setRePasswordView(!rePasswordView)} /> : <img width="80%" src={imgPasswordInVisible} onClick={() => setRePasswordView(!rePasswordView)} alt="view" />}
                  </div>
                  <div className="error-parent">
                    <span className="custom-error">
                      {errors.confirmPassword &&
                        touched.confirmPassword &&
                        errors.confirmPassword}
                    </span>
                  </div>
                </div>

                <button
                  className="form-control button-input"
                  type="submit"
                  disabled={loader}
                >
                  {loader ? "Loading..." : "SUBMIT PASSWORD"}
                </button>
              </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }

  function Step4() {
    return (
      <div>
        <div className="col-8 input-fields1">
          {/* <Formik
            initialValues={{
              otp: ""
            }}
            validationSchema={schema.signUpSchema4}
            // onSubmit={(values) => handleOtp(values)}
            onSubmit={(values) => console.log(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => ( */}
          <form onSubmit={() => { }}>
            <OtpInput
              className="my-otp"
              value={otp}
              onChange={(value: any) => {
                // debugger
                setOtp(value)
              }}
              numInputs={4}
            />
            <div className="form-group checkbox">
              <span className="stay-safe mt-1 ml-1">
                Didn’t receive Code?{" "}
                {/* <button type="button" onClick={() => resendOtp()} className="link-button">
                  Resend Code
                </button> */}
              </span>
            </div>
            <button
              className="form-control button-input"
              // type="submit"
              onClick={() => handleOtp({})}
              disabled={loader}
            >
              SAVE & CONTINUE
            </button>
          </form>
          {/* )}
          </Formik> */}
        </div>
      </div>
    );
  }

  if (redirect) {
    return <Redirect to="/" />;
  }
  return (
    <div className="main-bg-1">
      <div className="container-fluid-1 h-100">
        <div className="login-row h-100">
          <div className="col-md-12">
            <div className="hero-title">
              <h4><Link style={{ color: '#78AAC3' }} className="hero-title" to="/">Covid Passport</Link></h4>
            </div>
          </div>
          <div className="col-md-6 d-flex">
            <div className="page-image">
              <img className="hero-image" src={signUpImage} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="sign-up">
              <span className="stay-safe">STAY SAFE</span>
              {
                stepCount === 2 && (
                  <h4 className="sign-up-title">Set Password</h4>
                )

              }
              {
                stepCount === 3 && (
                  <>
                    <h4 style={{ marginLeft: '5%' }} className="sign-up-title">OTP Verification</h4>
                    {email !== "" && <label style={{ marginLeft: '6%', marginBottom: '2%' }}>Enter the code sent to an {email}</label>}

                  </>
                )
              }
              {
                stepCount === 0 && (
                  <div>
                    <h4 className="sign-up-title mb-2">Sign Up</h4>
                    <span className="stay-safe mt-1">
                      Already have an account ?{" "}
                      <Link to="/EmailAccountLoginBlock" className="link-button">
                        Login here
                      </Link>
                    </span>
                  </div>
                )

              }
              {
                stepCount === 1 && (
                  <div>
                    <h4 className="sign-up-title">Sign Up</h4>
                    <span className="stay-safe mt-1">
                      Already have an account ?{" "}
                      <Link to="/EmailAccountLoginBlock" className="link-button">
                        Login Here
                      </Link>
                    </span>
                  </div>
                )

              }


              {stepCount === 0 && <Step1 />}
              {stepCount === 1 && <Step2 />}
              {stepCount === 2 && <Step3 />}
              {/* {stepCount === 3 && <Step4 />} */}
              {stepCount === 3 && (
                <div>
                  <div className="col-8 input-fields1">
                    <form>
                      <OtpInput
                        className="my-otp"
                        value={otp}
                        onChange={(value: any) => {
                          setOtp(value)
                        }}
                        numInputs={4}
                      />
                      <div className="form-group checkbox">
                        <span className="stay-safe mt-1 ml-1">
                          Didn’t receive Code?{" "}
                          <button type="button" onClick={() => hanldeResendOtp()} className="btn link-button">
                            Resend Code
                          </button>
                        </span>
                      </div>
                      <button
                        className="form-control button-input"
                        // type="submit"
                        onClick={handleOtp}
                        disabled={loader}
                      >
                        SAVE & CONTINUE
                      </button>
                    </form>
                  </div>
                </div>
              )}
              <div className="captcha col-lg-8 col-md-10 col-12">
                <span>
                  The site is protected by reCAPTCHA and the google{" "}
                  <span className="link-text" onClick={onHandlePrivacyPolicy}>Privacy Policy </span>
                  and {" "}<span className="link-text" onClick={onHandleTermsOfServices}>Terms of Service </span> apple.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
//@ts-ignore
export default withRouter(EmailAccountRegistration);