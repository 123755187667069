import React, { useState } from "react";
import { imgPasswordInVisible, imgPasswordVisible, imgVector } from "./assets";
import "../../scss/style.scss";
import { withRouter } from "react-router-dom";
import { openNotification, openErrorNotification } from "./Notification.web";
import { Field, Form, Formik, ErrorMessage } from "formik";
// import * as Yup from "yup";
// @ts-ignore
import isEmpty from "lodash/isEmpty";

const setPassword = require("./ForgotPasswordService.web");
const schema = require("./validationSchema/signinSchema.web");

function NewPassword(props: any) {

  const [passwordView, setPasswordView] = useState(false)
  const [rePasswordView, setRePasswordView] = useState(false)

  const newPassword = async (values: any) => {
    if (isEmpty(values.password) || isEmpty(values.confirmPassword)) {
      return
    }

    if (values.password === values.confirmPassword) {
      values = {
        new_password: values.confirmPassword,
      };
      console.log(values);
      const res = await setPassword.setPassword(values);
      console.log(res);
      if (res.success) {
        openNotification("Otp Verified Successfully, Please login", "success");
        props.history.push("/");
      }
    } else {
      openErrorNotification(" Password is Not Match", "error");
    }
  };

  const onHandlePrivacyPolicy = (): void => {
    props.history.push("/privacy-policy");
  }

  const onHandleTermsOfServices = (): void => {
    props.history.push("/terms-of-services");
  }
  return (
    <div className="main-bg">
      <div className="container-fluid">
        <div className="row">
          <div className="col-6">
            <div className="hero-title">
              <h4 className="hero-title">Covid Passport</h4>
            </div>
            <div className="page-image">
              <img className="hero-image" src={imgVector} />
            </div>
          </div>
          <div className="col-6">
            <div className="sign-up">
              <span className="stay-safe">STAY SAFE</span>
              <h4 className="sign-up-title">Set Password</h4>

              <div className="col-8">
                <Formik
                  initialValues={{
                    password: "",
                    confirmPassword: "",
                  }}
                  validationSchema={schema.ForgotPassword}
                  onSubmit={(values) => {
                    newPassword(values);
                    console.log(values);
                  }}
                  enableReinitialize
                >
                  {({ }) => (
                    //@ts-ignore
                    <Form>
                      {/* //input box */}
                      <div className="form-group">
                        <Field
                          type={!passwordView ? "password" : "text"}
                          placeholder="Password"
                          name="password"
                          className="text-input form-control"
                        />
                        <div className="password-view1">
                          {!passwordView ? <img width="80%" src={imgPasswordVisible} alt="view" onClick={() => setPasswordView(!passwordView)} /> : <img width="80%" src={imgPasswordInVisible} onClick={() => setPasswordView(!passwordView)} alt="view" />}
                        </div>
                        <span className="error">
                          {<ErrorMessage name="password" />}
                        </span>
                      </div>
                      <div className="form-group">
                        <Field
                          type={!rePasswordView ? "password" : "text"}
                          placeholder="Confirm Password"
                          name="confirmPassword"
                          className="text-input form-control"
                        />
                        <div className="password-view1">
                          {!rePasswordView ? <img width="80%" src={imgPasswordVisible} alt="view" onClick={() => setRePasswordView(!rePasswordView)} /> : <img width="80%" src={imgPasswordInVisible} onClick={() => setRePasswordView(!rePasswordView)} alt="view" />}
                        </div>
                        <span className="error">
                          <ErrorMessage name="confirmPassword" />
                        </span>
                      </div>

                      <button type="submit" className="btn btn-grey w-100 mt-2 ml-4">
                        Submit Password
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
              <div className="captcha col-8 ml-4">
                <span>
                  The site is protected by reCAPTCHA and the google{" "}
                  <span className="link-text" onClick={onHandlePrivacyPolicy}>Privacy Policy </span>
                  and <span className="link-text" onClick={onHandleTermsOfServices}>Terms of Service </span> apple.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
export default withRouter(NewPassword);
