import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { imgVector } from "./assets";
import "./loginstyle.css";

import { openNotification } from "./Notification.web";
import { Formik } from "formik";
import { Link, Redirect } from "react-router-dom";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

export const userSignIn = require("./EmailAccountLoginService.web");

const schema = require("./validationSchema/signinSchema.web");

function EmailAccountLoginBlock(props: any) {
  const [loader, setLoader] = useState(false);
  const [roleId, setRoleId] = useState();
  const [redirect, setRedirect] = useState(false);
  const [passwordView, setPasswordView] = useState(false)

  const handleLogin = async (values: any) => {
    setLoader(true);
    const result = await userSignIn.userSignIn(values);
    if (result.success) {
      window.localStorage.setItem(
        "token",
        result && result.meta && result.meta.token
      );
      setLoader(false);
      console.log(result);

      setRoleId(result.account.role_id);
      setRedirect(true);

      openNotification("Successfully login", "success");
    } else {
      setLoader(false);
      console.log("error from Login:", result.error);
    }
    setLoader(false);
  };

  const onHandlePrivacyPolicy = (): void => {
    props.history.push("/privacy-policy");
  }

  const onHandleTermsOfServices = (): void => {
    props.history.push("/terms-of-services");
  }

  if (redirect) {
    if (roleId === 1) {
      return <Redirect to="UserConsole" />;
    }
    if (roleId === 2) {
      return <Redirect to="ClinicConsole" />;
    }
  }

  return (
    <div className="main-bg">
      <div className="container-fluid">
        <div className="row col-12">
          <div className="col-md-12">
            <div className="hero-title">
            <h4><Link style={{color: '#78AAC3'}} className="hero-title" to="/">Covid Passport</Link></h4>
            </div>
          </div>
          <div className="col-md-6 d-flex">

            <div className="page-image">
              <img className="hero-image" src={imgVector} />
            </div>
          </div>
          <div className="col-md-6 ">
            <div className="sign-up">
              <span className="stay-safe">STAY SAFE</span>
              <h4 className="sign-up-title mb-2">Login</h4>
              <span className="stay-safe">
                Don't have an account ?{" "}
                <Link to="/EmailAccountRegistration" className="link-button">
                  Sign Up Here
                </Link>
              </span>
              <div className="col-lg-8 col-md-10">
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={schema.signinSchema}
                  onSubmit={(values) => {
                    handleLogin(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <input
                          className="text-input form-control"
                          type="text"
                          name="email"
                          placeholder="Email"
                          onChange={handleChange}
                          // onBlur={handleBlur}
                          value={values.email}
                        />
                        <span className="error">
                          {" "}
                          {errors.email && touched.email && errors.email}
                        </span>
                      </div>
                      <div className="form-group">
                        <input
                          className="text-input form-control"
                          type={!passwordView ? "password" : "text"}
                          name="password"
                          placeholder="Password"
                          onChange={handleChange}
                          // onBlur={handleBlur}
                          value={values.password}
                        />
                        <div className="password-view">
                          {!passwordView ? <img width="80%" src={imgPasswordVisible} alt="view" onClick={() => setPasswordView(!passwordView)} /> : <img width="80%" src={imgPasswordInVisible} onClick={() => setPasswordView(!passwordView)} alt="view" />}
                        </div>
                        <span className="error">
                          {errors.password && touched.password && errors.password}
                        </span>
                      </div>
                      <span className="stay-safe mt-1">
                        <Link
                          to="/ForgotPassword"
                          className="link-button"
                        >
                          Forgot Password ?
                        </Link>
                      </span>
                      <button
                        className="form-control button-input"
                        type="submit"
                        disabled={loader}
                      >
                        {loader ? "Loading..." : "LOGIN"}
                      </button>
                    </form>
                  )}
                </Formik>
              </div>
              <div className="captcha col-lg-8 col-md-10 col-12">
                <span>
                  The site is protected by reCAPTCHA and the google{" "}
                  <span
                    className="link-text"
                    onClick={onHandlePrivacyPolicy}
                  >
                    Privacy Policy
                  </span>
                  {" "}
                  and
                  {" "}
                  <span
                    className="link-text"
                    onClick={onHandleTermsOfServices}
                  >
                    Terms of Service
                  </span> apple.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default withRouter(EmailAccountLoginBlock)