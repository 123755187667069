import React, { useState } from "react";
import { withRouter, Link } from "react-router-dom";
//Customizable Area Start
import { imgVector } from "./assets";
// import "../../scss/style.scss";
import './forget.css'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { openNotification } from "./Notification.web";
export const userForgotPass = require("./ForgotPasswordService.web");
//Customizable Area End

function ForgotPassword(props: any) {
  const [resendStatus, setresendStatus] = useState(false);
  const [emailValue, setemailValue] = useState("");
  const forgotHandle = async (values: any) => {
    if (values.email.includes("@")) {
      values = { email: values.email };
    } else {
      values = { phone_number: values.email };
    }
    const result = await userForgotPass.userForgotPass(values);

    if (result.success) {
      if (result?.meta?.token) {
        localStorage.setItem("token", result.meta.token)
      }
      setresendStatus(true);
      openNotification("OTP sent on your registered email address", "success");
      setTimeout(() => {
        props.history.push({
          pathname: "/ForgotPasswordOtp",
          data: { values },
        });
      }, 3 * 1000);
    } else {
      console.log("error from userSignUp:", result.error);
    }
  };
  const resendHandler = async () => {
    let values: any = {
      email: emailValue,
    };

    if (values.email.includes("@")) {
      values = { email: values.email };
    } else {
      values = { phone_number: values.email };
    }
    const result = await userForgotPass.userForgotPass(values);
    if (result.success) {
      openNotification(result.message, "success");
      setTimeout(() => {
        props.history.push({
          pathname: "/ForgotPasswordOtp",
          data: { values },
        });
      }, 3 * 1000);
    } else {
      console.log("error from userSignUp:", result.error);
    }
  };

  const onHandlePrivacyPolicy = (): void => {
    props.history.push("/privacy-policy");
  }

  const onHandleTermsOfServices = (): void => {
    props.history.push("/terms-of-services");
  }

  return (
    <div className="main-bg">
      <div className="container-fluid h-100">
        <div className="row h-100">
          <div className="col-md-12">
            <div className="hero-title">
              <h4><Link style={{ color: '#78AAC3' }} className="hero-title" to="/">Covid Passport</Link></h4>
            </div>
          </div>
          <div className="col-md-6 d-flex">
            <div className="page-image">
              <img className="hero-image" src={imgVector} />
            </div>
          </div>
          <div className="col-md-6">
            <div className="sign-up">
              <span className="stay-safe">STAY SAFE</span>
              <h4 className="sign-up-title">Forgot Password?</h4>

              <div className="col-lg-8">
                <Formik
                  initialValues={{
                    email: emailValue || "",
                  }}

                  onSubmit={(values) => {
                    setemailValue(values.email);
                    forgotHandle(values);
                  }}
                  enableReinitialize
                >
                  {({ }) => (
                    //@ts-ignore
                    <Form>
                      <div className="flex-center ml-4">
                        <p style={{ fontSize: '15px' }}>Confirm your email/contact number </p>
                      </div>
                      <div className="form-group">
                        <Field
                          type="text"
                          placeholder="Email/ Contact Number"
                          name="email"
                          className="text-input form-control"
                        />
                        <span className="error">
                          <ErrorMessage name="email" />
                        </span>
                      </div>
                      {resendStatus === false ? (
                        <>
                          <button
                            type="submit"
                            className="button btn-grey w-100 mt-2 forget-pass-btns"
                          >
                            SEND REQUEST
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="button btn-grey w-100 mt-2 forget-pass-btns"
                            disabled
                          >
                            SEND REQUEST
                          </button>
                        </>
                      )}

                      {/* <a
                          onClick={resendHandler}
                          className="link font-18 mt-2 forget-pass-btns"
                        >
                          Resend Request
                        </a>
                        {resendStatus === true ? (
                          <>
                            <p className="font-18 color-blue mt-2 ml-4">
                              We have sent a reset password link to the entered
                            </p>
                          </>
                        ) : null} */}
                    </Form>
                  )}
                </Formik>
              </div>
              <div className="forgot-captcha col-lg-8 col-md-10 col-12">
                <span>
                  The site is protected by reCAPTCHA and the google{" "}
                  <span className="link-text" onClick={onHandlePrivacyPolicy}>Privacy Policy </span>
                  and <span className="link-text" onClick={onHandleTermsOfServices}>Terms of Service </span> apple.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default withRouter(ForgotPassword);
// Customizable Area Start
