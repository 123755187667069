import { notification } from "antd";

export const openNotification = (success:any,types:any) => {
  notification.open({
    message: types,
    type: types,
    duration: 3,
    description: success,
  });
};
export const openErrorNotification = (error:any,types:any) => {
  notification.open({
    message: "Error",
    // type: types,
    duration: 3,
    description: error,
  });
};