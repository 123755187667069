import * as yup from 'yup';
import getValidations from './validation';



const signinSchema = () => {
    const validations = getValidations();
    return yup.object().shape({
        email: validations.email.required("Email is required."),
        password: validations.password.required("Password is required."),
    });
}


export {
    signinSchema
};
