import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { bg1, UploadIcon } from "./assets";
import { openErrorNotification } from "./Notification.web";
// @ts-ignore
import map from "lodash/map";
// @ts-ignore
import cloneDeep from "lodash/cloneDeep";
// @ts-ignore
import isEmpty from "lodash/isEmpty";
// @ts-ignore
import find from "lodash/find";
// @ts-ignore
import reject from "lodash/reject";
// @ts-ignore
import includes from "lodash/includes";
// @ts-ignore
import remove from "lodash/remove";

import { Modal, Button } from 'antd';
import Navigation from "../../AdminConsole/src/Navigation.web";
const userGetAllQuestions = require("./UserSurveyFormService.web");
import './regmstyle.css'

function ClinicSurveyScreen1(props: any) {
  const [vaccinationDetails, setvaccinationDetails] = useState([]);
  const [shouldShowBookedVaccinationSlot, setShouldShowBookedVaccinationSlot] = useState(false);
  const [clinics, setClinics] = useState([]);
  const [vaccines, setVaccines] = useState([]);
  const [shouldShowEmailNContact, setShouldShowEmailNContact] = useState(false);
  const [vaccineProf, setVaccineProof] = useState();
  const [email, setEmail] = useState("");
  const [clinicName, setClinicName] = useState("");
  const [vaccineName, setVaccineName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [surveyAnswer, setSurveyAnswer] = useState<any>([]);
  const [name, setName] = useState(window.localStorage.getItem('Name'))

  useEffect(() => {
    getAllQuestions();
    getVaccinesAndClinicNames();
  }, []);

  const isdisable = (): boolean => {
    let shouldDisable;
    if (shouldShowBookedVaccinationSlot) {
      shouldDisable = !(surveyAnswer.length > 1)
    } else {
      shouldDisable = !(surveyAnswer.length === 1);
      // @ts-ignore
      if (!shouldShowBookedVaccinationSlot && (
        isEmpty(clinicName) ||
        isEmpty(vaccineName) ||
        (clinicName === "other" && (isEmpty(email) || isEmpty(contactNumber)))
      )) {
        shouldDisable = true;
      }
    }

    return shouldDisable;
  }

  const getAllQuestions = async () => {
    const result = await userGetAllQuestions.userGetAllQuestions();
    if (result.success) {
      const arr = result?.questions?.vaccination;
      setvaccinationDetails(arr);
      // openNotification("Successfully get all questions", "success");
    } else {
      console.log("error from error :", result.error);
    }
  };

  const getVaccinesAndClinicNames = async () => {
    const result = await userGetAllQuestions.getVaccinesAndClinicNames();
    if (result.success) {
      console.log("vaccinationDetails", result);
      setClinics(result?.clinics);
      setVaccines(result?.vaccines?.data);
      // openNotification("Successfully get all questions", "success");
    } else {
      console.log("error from error :", result.error);
    }
  }

  const onHandleChangeOfTakenVaccination = (questionID: any, optionID: any, typeOfQuestion: any, name: string) => {
    setShouldShowBookedVaccinationSlot(name.toUpperCase() === "NO");
    if (name.toUpperCase() === "YES") {

      const onlyYesQuestions = find(surveyAnswer, { survey_question_id: questionID });
      // setSurveyAnswer([onlyYesQuestions]);
      // setTimeout(function () {
      if (!isEmpty(onlyYesQuestions)) {
        onHandleChangeQuestionAnswer(questionID, optionID, typeOfQuestion, [onlyYesQuestions]);
      } else {
        onHandleChangeQuestionAnswer(questionID, optionID, typeOfQuestion);
      }
      // }, 3000);
    } else {
      setClinicName("");
      setVaccineName("");
      setContactNumber("");
      setEmail("");
      onHandleChangeQuestionAnswer(questionID, optionID, typeOfQuestion);
    }
  }

  function ImageUploader() {
    const thumbsContainer = {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      marginTop: 16,
    };

    const thumb = {
      display: "inline-flex",
      borderRadius: 2,
      border: "1px solid #eaeaea",
      marginBottom: 8,
      marginRight: 8,
      width: 100,
      height: 100,
      padding: 4,
      boxSizing: "border-box",
    };

    const thumbInner = {
      display: "flex",
      minWidth: 0,
      overflow: "hidden",
    };

    const img = {
      display: "block",
      width: "100%",
      height: "100%",
    };

    const [files, setFiles] = useState([]);
    const [file, setFile] = useState([]);
    const [openImage, setOpenImage] = useState(false);
    const [imageUrl, setImageUrl] = useState();


    const remove = (file: any) => {
      const newFiles = [...files];     // make a var for the new array
      newFiles.splice(file, 1);        // remove the file from the array
      setFiles(newFiles);              // update the state
    };

    const { getRootProps, getInputProps } = useDropzone({
      accept: "image/*",
      onDrop: (acceptedFiles: any) => {
        onHandleFileUpload(cloneDeep(acceptedFiles));
        setFiles(
          acceptedFiles.map((file: any) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });
    const thumbs = files.map((file: any) => (
      <div
        style={{
          display: "inline-flex",
          borderRadius: 2,
          border: "1px solid #eaeaea",
          marginBottom: 8,
          marginRight: 8,
          width: 100,
          height: 100,
          padding: 4,
          boxSizing: "border-box",
        }}
        key={file.name}
      >
        <div style={thumbInner}>
          <img
            onClick={() => handleShowDialog(file.preview)}
            src={file.preview}
            style={img}
          />
        </div>
        <div>
          <button className="btn btn-outline-secondary" type="button" onClick={() => remove(file)} style={{ zIndex: 100 }} >x</button>
        </div>
      </div>
    ));

    useEffect(
      () => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        files.forEach((file: any) => URL.revokeObjectURL(file.preview));
      },
      [files]
    );

    useEffect(() => {
      localStorage.setItem("vaccineProof", JSON.stringify(file))
    }, [file])

    const onHandleFileUpload = (file: any) => {
      // @ts-ignore
      window.vaccineProof = cloneDeep(file);
    }

    const handleShowDialog = (file: any) => {
      setOpenImage(!openImage);
      setImageUrl(file);
    };
    return (
      <section className="image-input">
        <div {...getRootProps({ className: "" })}>
          <input name="idProof" {...getInputProps()} className="image-input" />
          {/* <p style={{ paddingTop: "10px" }}>click to select files</p> */}
          <div className="fileUpload  file-ud-flex justify-content-center align-items-center">
            <img src={UploadIcon} className="upload-icon" />
            <span>Upload files</span>
          </div>

        </div>
        <aside
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            marginTop: 16,
            paddingLeft: 10,
          }}
        >
          {thumbs}
        </aside>
        {/* {openImage && (
          <dialog className="dialog" style={{ position: "absolute" }} open>
            <img
              className="close-btn"
              src={closeImage}
              onClick={() => handleShowDialog("file")}
            />
            <img className="image" src={imageUrl} alt="Id Proof" />
          </dialog>
        )} */}
        <Modal
          // title="Document preview"
          visible={openImage}
          footer={null}
          onCancel={() => setOpenImage(false)}
          className="image-preview-modal"
        >
          <img className="image" src={imageUrl} alt="Id Proof" />
        </Modal>
      </section>
    );
  }

  const onHandleClinicChange = (event: any) => {
    setShouldShowEmailNContact(event === "string" ? event.trim() === "other" : event?.target?.value.trim() === "other")
    if (event.target?.value) {
      setClinicName(event.target?.value);
    }
  }

  const onHandleVaccineChange = (event: any) => {
    if (event.target?.value) {
      setVaccineName(event.target?.value);
    }
  }

  const onHandleChangeEmail = (event: any) => {
    if (event.target?.value) {
      setEmail(event.target?.value);
    }
  };

  const onHandleChangeContactNumber = (event: any) => {
    if (event.target?.value) {
      setContactNumber(event.target?.value);
    }
  };

  const onHandleChangeStepper = (route: any) => {
    props.history.push(route)
  }

  const onHandleChangeQuestionAnswer = (questionID: any, answerID: any, typeOfQuestion: any, updatedSurvey: any = []) => {
    let cloneSurveyAnswer = isEmpty(updatedSurvey) ? cloneDeep(surveyAnswer) : cloneDeep(updatedSurvey);

    let obj = {
      survey_question_id: questionID,
      survey_option_ids: [answerID],
      answer: "",
    }

    if (isEmpty(cloneSurveyAnswer)) {
      cloneSurveyAnswer.push(obj);
      setSurveyAnswer(cloneSurveyAnswer);
    } else if (!isEmpty(cloneSurveyAnswer) && typeOfQuestion === "single select") {
      if (!isEmpty(find(cloneSurveyAnswer, { survey_question_id: questionID }))) {
        cloneSurveyAnswer = reject(cloneSurveyAnswer, { survey_question_id: questionID })
        cloneSurveyAnswer.push(obj);
        setSurveyAnswer(cloneSurveyAnswer);
      } else {
        cloneSurveyAnswer.push(obj);
        setSurveyAnswer(cloneSurveyAnswer);
      }
    } else if (!isEmpty(cloneSurveyAnswer) && typeOfQuestion === "multi select") {
      // record not exits
      if (isEmpty(find(cloneSurveyAnswer, { survey_question_id: questionID }))) {
        cloneSurveyAnswer.push(obj);
        setSurveyAnswer(cloneSurveyAnswer);
      } else {
        // record exits
        map(cloneSurveyAnswer, (surveyanswer: any) => {
          if (includes(surveyanswer.survey_option_ids, answerID)) {
            // if id is same
            surveyanswer.survey_option_ids = remove(surveyanswer.survey_option_ids, function (n: any) { return n !== answerID })
            // serveyanswsurveyanswer.survey_option_idser.survey_option_ids = reject(surveyanswer.survey_option_ids, answerID)
          } else if (surveyanswer.survey_question_id === questionID) {
            // if id is not same
            surveyanswer.survey_option_ids = [...surveyanswer.survey_option_ids, answerID]
          }
        })
        setSurveyAnswer(cloneSurveyAnswer);
      }
    }
  }

  const onHandleSubmitQuestions = async () => {
    // @ts-ignore
    if (!shouldShowBookedVaccinationSlot && (isEmpty(window.vaccineProof) ||
      isEmpty(clinicName) ||
      isEmpty(vaccineName) ||
      (clinicName === "other" && (isEmpty(email) || isEmpty(contactNumber)))
    )) {
      // @ts-ignore
      if (isEmpty(window.vaccineProof)) {
        openErrorNotification("Please upload vaccine proof", "error");
      }
      return;
    }


    const recipientId = localStorage.getItem("recipientId");
    let publicUrlResult;
    if (!shouldShowBookedVaccinationSlot) {
      // @ts-ignore
      publicUrlResult = await userGetAllQuestions.getPublicUrl(window.vaccineProof);
    } else {
      const obj = {
        data: {
          attributes: {
            survey_answers: surveyAnswer
          },
        },
      };

      const result = await userGetAllQuestions.submitQuestionsAnswers(obj);
      if (result.success) {
        props.history.push("/SurveySubmission")
      } else {
        console.error("error from error :", result.error);
      }
    }

    if (publicUrlResult?.success) {
      let data = {}
      const obj = {
        data: {
          attributes: {
            survey_answers: surveyAnswer
          },
        },
      };

      await userGetAllQuestions.submitQuestionsAnswers(obj);
      if (clinicName === "other") {
        data = {
          vaccine_id: vaccineName,
          recipient_id: recipientId,
          clinic_id: "",
          clinic_type: "other",
          vaccine_proof: [publicUrlResult?.public_url],
          approval_status: "pending",
          clinic_payload: {
            email,
            "contact_number": contactNumber
          }
        }
      } else {
        data = {
          vaccine_id: vaccineName,
          recipient_id: recipientId,
          clinic_id: clinicName,
          clinic_type: "",
          vaccine_proof: [publicUrlResult?.public_url],
          approval_status: "pending",
        }
      }

      const result = await userGetAllQuestions.vaccineRegistration(data);
      if (result.success) {
        props.history.push("/SurveySubmission")
      } else {
        console.error("error from error :", result.error);
      }
    }
  }
  return (
    <div className="container">
      <div className="main-wrapper container">
        <div className="navigation">
          <Navigation />
        </div>
        <div className="survey-main">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="survey-form">
                  <div className="repeated-section">
                    <div className="vaccination-detail">
                      <p className="user-name">
                        Hello, <span>{name}</span>
                      </p>
                      <h3>What do you feel about the Covid-19 Vaccine?</h3>
                      <p className="covide-infomation">
                        Please note that your inputs will supplement the efforts being
                        taken.
                      </p>
                      <img src={bg1} className="background-image" />
                    </div>
                  </div>

                  {/* //30 */}
                  <div className="flex-between">
                    <div className="give-detail-steps">
                      {
                        map(vaccinationDetails[0], (vaccinationDetail: any, index: any) => {
                          console.log("================ vaccinationDetail ===>>>>>>>>> ", vaccinationDetail)
                          {
                            return (
                              <div className="questions">
                                <p className="Raleway">
                                  <span>Q 8. {vaccinationDetail?.attributes?.question}</span>

                                </p>
                                {
                                  map(vaccinationDetail?.attributes?.survey_options?.data, (option: any) => {
                                    // console.log("============ OPTIONS =======", option) 
                                    return (
                                      <div className="answer-wrapper Raleway">
                                        <input
                                          type="radio"
                                          name={`select-${vaccinationDetail.data?.attributes?.id}`}
                                          id={option?.id}
                                          onClick={() => onHandleChangeOfTakenVaccination(vaccinationDetail.id, option?.id, vaccinationDetail?.attributes?.option_type, option?.attributes?.name)}
                                        />
                                        <label htmlFor={option?.id} className="option option-1">
                                          <span> {option?.attributes?.name.toUpperCase()}</span>
                                        </label>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            )
                          }
                        })
                      }
                      {
                        // console.log(shouldShowBookedVaccinationSlot)
                        console.log(vaccinationDetails)
                      }
                      {
                        shouldShowBookedVaccinationSlot && (
                          <div>
                            {
                              map(vaccinationDetails[1], (vaccinationDetail: any, index: any) => {
                                {
                                  return (
                                    <div className="questions">
                                      <p className="Raleway">
                                        <span>Q 9. {vaccinationDetail?.attributes?.question}</span>

                                      </p>
                                      {
                                        map(vaccinationDetail?.attributes?.survey_options?.data, (option: any) => {
                                          return (
                                            <div className="answer-wrapper Raleway">
                                              <input
                                                type="radio"
                                                name={`select${vaccinationDetail.data?.attributes?.id}`}
                                                id={option?.id}
                                                onClick={() => onHandleChangeQuestionAnswer(vaccinationDetail.id, option?.id, vaccinationDetail?.attributes?.option_type)}
                                              />
                                              <label htmlFor={option?.id} className="option option-1">
                                                <span> {option?.attributes?.name.toUpperCase()}</span>
                                              </label>
                                            </div>
                                          )
                                        })
                                      }
                                    </div>
                                  )
                                }
                              })
                            }
                          </div>
                        )
                      }
                      {
                        !shouldShowBookedVaccinationSlot && (

                          <div className="questions">
                            <p className="Raleway">Please enter your vaccine details</p>
                            <div className="vaccine-detail">
                              <div>
                                <select
                                  className="custom-select"
                                  onChange={onHandleClinicChange}
                                >
                                  <option>Clinic Name</option>
                                  {
                                    // @ts-ignore
                                    map(clinics?.data, (clinic: any) => {
                                      return (
                                        <option value={clinic?.id}>{clinic?.attributes?.first_name}</option>
                                      )
                                    })
                                  }
                                  <option value="other">Other</option>
                                </select>
                              </div>
                              {
                                shouldShowEmailNContact && (
                                  <div>
                                    <div>
                                      <input
                                        type="text"
                                        placeholder="Clinic Name"
                                        className="mr-1"
                                        onChange={(e) => setClinicName(e.target.value)}
                                      />
                                    </div>
                                    <div className="d-flex">
                                      <input
                                        type="text"
                                        placeholder="Email"
                                        className="mr-1"
                                        onChange={onHandleChangeEmail}
                                      />
                                      <input
                                        type="number"
                                        placeholder="Contact Number"
                                        className="ml-1"
                                        onChange={onHandleChangeContactNumber}
                                      />
                                    </div>

                                  </div>
                                )
                              }

                              <div>
                                <select
                                  className="custom-select"
                                  onChange={onHandleVaccineChange}
                                >
                                  <option>Select Vaccine</option>
                                  {map(vaccines, (vaccine: any) => {
                                    return (
                                      <option
                                        value={vaccine?.id}
                                      >
                                        {vaccine?.attributes?.name.toUpperCase()}
                                      </option>
                                    )
                                  })}
                                </select>
                              </div>
                              <div className="upload-proof">
                                <span>Vaccine Proof</span>
                                <ImageUploader />
                              </div>
                            </div>
                          </div>

                        )
                      }
                    </div>
                    {/* Form Stepper - add class "active"*/}
                    <div className="form-steps step3" style={{ height: '500px' }}>
                      <div
                        className="step active"
                      // onClick={() => onHandleChangeStepper("/SurveyPersonalDetails")}
                      >{/* add class "active"*/}
                        <span className="number">1</span> {/* add onclick for routing */}
                        <p>Personal Details</p>
                        <div className="step-border"><div className="active-border"></div></div>
                      </div>
                      <div
                        className="step active"
                      // onClick={() => onHandleChangeStepper("/Survey")}
                      >
                        <span className="number">2</span>
                        <p>Survey</p>
                        <div className="step-border"><div className="active-border"></div></div>
                      </div>
                      <div className="step active">
                        <span className="number">3</span>
                        <p>Vaccination</p>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="save-continue">
                  <button
                    className={isdisable() ? "button button-primary btn-gry" : "button button-primary"}
                    onClick={onHandleSubmitQuestions}
                    disabled={isdisable()}
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}


export default withRouter(ClinicSurveyScreen1)