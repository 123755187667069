import React, { Component } from "react";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import { Link, withRouter} from "react-router-dom";
// Customizable Area End
import "./regstyle.css";
import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
import { doctor, recipient, signUpImage } from "./assets";
import { Formik } from "formik";
import ProgressBar from "react-bootstrap/ProgressBar";

class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  onHandlePrivacyPolicy = (): void => {
    // @ts-ignore
    this.props.history.push("/privacy-policy");
  }

  onHandleTermsOfServices = (): void => {
    // @ts-ignore
    this.props.history.push("/terms-of-services");
  }

  render() {
    return (
      <div className="main-bg">
        <div className="container-fluid">
        <div className="row">
          <div className="col-6">
            <div className="hero-title">
              <h4>Covid Passport</h4>
            </div>
            <div className="page-image">
              <img className="hero-image" src={signUpImage} />
            </div>
          </div>
          <div className="col-6">
            <div className="sign-up">
              <span className="stay-safe">STAY SAFE</span>
              <h4 className="sign-up-title">OTP Verification</h4>
              <div>
                <div className="col-8 input-fields">
                  <Formik
                    initialValues={{ otp: "" }}
                    // validationSchema={signUpSchema3}
                    onSubmit={(values) => console.log(values)}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <input
                            className="text-input form-control"
                            type="number"
                            name="otp"
                            placeholder="Enter OTP"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.otp}
                          />
                          <span className="error">
                            {errors.otp && touched.otp && errors.otp}
                          </span>
                        </div>

                        <div className="form-group checkbox">
                          <span className="stay-safe mt-1">
                            Didn’t receive Code?{" "}
                            <Link to="#" className="link-button">
                              Resend Code
                            </Link>
                          </span>
                        </div>
                        {/* <button
                          className="form-control button-input"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          SAVE & CONTINUE
                        </button> */}
                        <Link
                          className="form-control button-input"
                          to="/EmailAccountRegistration4"
                          style={{ textAlign: "center" }}
                        >
                          SUBMIT & CONTINUE
                        </Link>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>

              <div className="captcha col-8">
                <span>
                  The site is protected by reCAPTCHA and the google{" "}
                  <span className="link-text" onClick={this.onHandlePrivacyPolicy}>Privacy Policy</span>
                  and <span className="link-text" onClick={this.onHandleTermsOfServices}>Terms</span> of Service apple.
                </span>
              </div>
            </div>
          </div>
        </div>
        </div>
       
      </div>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    // Customizable Area End
  }
}
// @ts-ignore
export default withRouter(EmailAccountRegistration);
