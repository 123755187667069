Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AdminConsole";
exports.labelBodyText = "AdminConsole Body";

exports.btnExampleTitle = "CLICK ME";
exports.serverURL = "https://covidpassportsystem1-71184-ruby.b71184.dev.eastus.az.svc.builder.cafe/";
// Customizable Area End