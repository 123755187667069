import * as yup from 'yup';

export default function validation() {
    return ({
        experience: yup
            .string()
            .required("Experience is required."),
        occupation: yup
            .string()
            .min(3, "Minimum 3 characters are required")
            .max(20, "Maximum 20 characters are allowed.")
            .matches(/^[a-zA-Z ]+$/, "Only letters are allowed."),
        email: yup
            .string()
            .email("Please enter a valid email address.")
            .min(3, "Minimum 3 characters are required")
            .max(50, "Maximum 50 characters are allowed.")
            .required("Email is required."),
        password: yup
            .string()
            .min(8, "Minimum Password length is 8.")
            .max(25, "Maximum Password length is 25")
            // .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/, "Password must contain atleast a capital letter, a lowercase letter, a number and a special character.")
            .required("Password is required."),
        confirmpassword: yup
            .string()
            .oneOf([yup.ref("password"), null], "Passwords must match")
            .required("Password is required."),
        currentpassword: yup
            .string()
            .required("Password is required."),
        mobile: yup
            .number()
            .typeError("Only numbers are allowed.")
            .required("Mobile number is required.")
            .positive("Negative numbers are not allowed.")
            .integer("Number can't contain a decimal.")
            .min(10000000, "Minimum 5 digits are required.")
            .max(9999999999999, "Maximum 11 digits are allowed."),
        otp: yup
            .number()
            .typeError("Only numbers are allowed.")
            .required("OTP is required.")
            .positive("Negative numbers are not allowed.")
            .integer("Number can't contain a decimal.")
            .min(100000, "Minimum 6 digits are required.")
            .max(999999, "Maximum 6 digits are allowed."),
        accountNumber: yup
            .number()
            .typeError("Only numbers are allowed.")
            .required("Account number is required.")
            .positive("Negative numbers are not allowed.")
            .integer("Number can't contain a decimal.")
            .min(100000, "Minimum 6 digits are required.")
            .max(999999999999, "Maximum 12 digits are allowed."),
        name: yup
            .string()
            .min(3, "Minimum 3 characters are required")
            .max(20, "Maximum 20 characters are allowed.")
            .matches(/^[a-zA-Z ]+$/, "Only letters are allowed."),
        houseNumber: yup
            .string(),
        address: yup
            .string(),
        city: yup
            .string(),
        state: yup
            .string(),
        country: yup
            .string(),
        pincode: yup
            .number()
            .typeError("Only numbers are allowed.")
            .positive("Negative numbers are not allowed.")
            .integer("Pincode can't contain a decimal.")
            .min(100000, "Minimum 6 digits are required.")
            .max(999999, "Maximum 6 digits are allowed."),
        phone: yup
            .number()
            .transform(value => (isNaN(value) ? undefined : value))
            .typeError("Only numbers are allowed.")
            .positive("Negative numbers are not allowed.")
            .integer("Phone can't contain a decimal.")
            .min(1000000000, "Minimum 10 digits are required.")
            .max(9999999999, "Maximum 10 digits are allowed."),

        description: yup
            .string()
            .typeError("Message is required..")
            .test("Minimum 30 characters are required.", val => val?.length > 29),
        subject: yup
            .string(),
        comment: yup
            .string()
            .typeError("Comment is required."),
        rating: yup
            .number()
            .required("Rating is required."),
        file: yup
            .string()

    })
}