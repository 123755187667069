import axios from 'axios';
import { openErrorNotification } from './Notification.web';
const configJSON = require('./config');

const userGetAllQuestions = async function() {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get(
      `${configJSON.serverURL}/bx_block_survey/surveys`,

      {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    if (e?.response?.data?.errors) {
      map(e.response.data.errors, (error) => {
        openErrorNotification(Object.values(error)[0], 'error');
      });
    } else if (e?.response?.data?.message) {
      openErrorNotification(
        e && e.response && e.response.data && e.response.data.message,
        'error'
      );
    } else {
      openErrorNotification(
        'Something went wrong, Please try again later',
        'error'
      );
    }
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};

const submitQuestionsAnswers = async function(data) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios.post(
      `${configJSON.serverURL}/bx_block_survey/surveys/save_multiple_answers`,
      data,
      {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    if (e?.response?.data?.errors) {
      map(e.response.data.errors, (error) => {
        openErrorNotification(Object.values(error)[0], 'error');
      });
    } else if (e?.response?.data?.message) {
      openErrorNotification(
        e && e.response && e.response.data && e.response.data.message,
        'error'
      );
    } else {
      openErrorNotification(
        'Something went wrong, Please try again later',
        'error'
      );
    }
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};

const getVaccinesAndClinicNames = async function() {
  const token = localStorage.getItem('token');

  try {
    const response = await axios.get(
      `${configJSON.serverURL}/bx_block_vaccine/vaccines`,
      {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    if (e?.response?.data?.errors) {
      map(e.response.data.errors, (error) => {
        openErrorNotification(Object.values(error)[0], 'error');
      });
    } else if (e?.response?.data?.message) {
      openErrorNotification(
        e && e.response && e.response.data && e.response.data.message,
        'error'
      );
    } else {
      openErrorNotification(
        'Something went wrong, Please try again later',
        'error'
      );
    }
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};

const getPublicUrl = async function(file) {
  const token = localStorage.getItem('token');
  const formData = new FormData();

  formData.append('file', file[0]);
  try {
    const response = await axios.put(
      `${configJSON.serverURL}/image_block/get_public_url`,
      formData,
      {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    if (e?.response?.data?.errors) {
      map(e.response.data.errors, (error) => {
        openErrorNotification(Object.values(error)[0], 'error');
      });
    } else if (e?.response?.data?.message) {
      openErrorNotification(
        e && e.response && e.response.data && e.response.data.message,
        'error'
      );
    } else {
      openErrorNotification(
        'Something went wrong, Please try again later',
        'error'
      );
    }
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};

const vaccineRegistration = async function(data) {
  const token = localStorage.getItem('token');

  try {
    const response = await axios.post(
      `${
        configJSON.serverURL
      }/bx_block_vaccine_registration/vaccine_registrations`,
      data,
      {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          'Access-Control-Allow-Origin': '*',
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    if (e?.response?.data?.errors) {
      map(e.response.data.errors, (error) => {
        openErrorNotification(Object.values(error)[0], 'error');
      });
    } else if (e?.response?.data?.message) {
      openErrorNotification(
        e && e.response && e.response.data && e.response.data.message,
        'error'
      );
    } else {
      openErrorNotification(
        'Something went wrong, Please try again later',
        'error'
      );
    }
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};

export {
  userGetAllQuestions,
  submitQuestionsAnswers,
  getVaccinesAndClinicNames,
  getPublicUrl,
  vaccineRegistration,
};
