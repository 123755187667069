import axios from "axios";
import { openNotification, openErrorNotification } from "./Notification.web";
import map from "lodash/map";

export const configJSON = require("./config");
const create_recipient = async function (
  payload,
  idUrl,
  vaccineUrl,
  vaccine_id
) {
  try {
    console.log(vaccineUrl);
    console.log(idUrl);

    // debugger

    const formData = new FormData();

    formData.append("certificate", vaccineUrl);
    formData.append("first_name", payload.fullName);
    formData.append("full_phone_number", `91${payload.contactNumber}`);
    formData.append("email", payload.email);
    formData.append("profile_image", idUrl);
    formData.append("vaccine_id", vaccine_id);

    // const data = {
    //   first_name: payload.fullName,
    //   full_phone_number: payload.contactNumber,
    //   email: payload.email,
    //   certificates: VaccineUrl,
    //   profile_image: idUrl,
    //   vaccine_id: vaccine_id
    // };

    const response = await axios.post(
      `${configJSON.serverURL}bx_block_clinic/clinics/create_recipient`,
      formData,
      {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    console.log(e);
    if (e?.response?.data?.errors) {
      map(e.response.data.errors, (error) => {
        // openErrorNotification(Object.values(error)[0], "error");
        openErrorNotification('The email is already registered.', "error");
      });
    } else if (e?.response?.data?.message) {
      openErrorNotification(
        e && e.response && e.response.data && e.response.data.message,
        "error"
      );
    } else {
      openErrorNotification(
        "Something went wrong, Please try again later",
        "error"
      );
    }
    // openErrorNotification(
    //   e && e.response && e.response.data && e.response.data.message,
    //   'error'
    // );
    return { success: false, error: e };
  }
};

const getAccountDetails = async function (payload) {
  try {
    const response = await axios.get(
      `${configJSON.serverURL}account_block/accounts/${payload.id}`,

      {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    if (e?.response?.data?.errors) {
      map(e.response.data.errors, (error) => {
        openErrorNotification(Object.values(error)[0], "error");
      });
    } else if (e?.response?.data?.message) {
      openErrorNotification(
        e && e.response && e.response.data && e.response.data.message,
        "error"
      );
    } else {
      openErrorNotification(
        "Something went wrong, Please try again later",
        "error"
      );
    }
    return { success: false, error: e };
  }
};

const getRecipientDetails = async function () {
  try {
    const response = await axios.get(
      `${configJSON.serverURL
      }bx_block_vaccine_registration/vaccine_registrations/list_per_clinic`,
      {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    if (e?.response?.data?.errors) {
      map(e.response.data.errors, (error) => {
        openErrorNotification(Object.values(error)[0], "error");
      });
    } else if (e?.response?.data?.message) {
      openErrorNotification(
        e && e.response && e.response.data && e.response.data.message,
        "error"
      );
    } else {
      openErrorNotification(
        "Something went wrong, Please try again later",
        "error"
      );
    }
    return { success: false, error: e };
  }
};

const approve_request = async function (id, data) {
  try {
    const response = await axios.put(
      `${configJSON.serverURL
      }bx_block_vaccine_registration/vaccine_registrations/status_update/${id}`,
      data,
      {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    if (e?.response?.data?.errors) {
      map(e.response.data.errors, (error) => {
        openErrorNotification(Object.values(error)[0], "error");
      });
    } else if (e?.response?.data?.message) {
      openErrorNotification(
        e && e.response && e.response.data && e.response.data.message,
        "error"
      );
    } else {
      openErrorNotification(
        "Something went wrong, Please try again later",
        "error"
      );
    }
    return { success: false, error: e };
  }
};

const getSurveyAnswer = async function () {
  try {
    const response = await axios.get(
      `${configJSON.serverURL}bx_block_survey/surveys/get_survey_answers`,
      {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    if (e?.response?.data?.errors) {
      map(e.response.data.errors, (error) => {
        openErrorNotification(Object.values(error)[0], "error");
      });
    } else if (e?.response?.data?.message) {
      openErrorNotification(
        e && e.response && e.response.data && e.response.data.message,
        "error"
      );
    } else {
      openErrorNotification(
        "Something went wrong, Please try again later",
        "error"
      );
    }
    return { success: false, error: e };
  }
};

const approve_reject = async function (id, data) {
  try {
    const response = await axios.put(
      `${configJSON.serverURL
      }bx_block_vaccine_registration/vaccine_registrations/status_update/${id}`,
      data,
      {
        headers: {
          token: window.localStorage.getItem("token"),
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    if (e?.response?.data?.errors) {
      map(e.response.data.errors, (error) => {
        openErrorNotification(Object.values(error)[0], "error");
      });
    } else if (e?.response?.data?.message) {
      openErrorNotification(
        e && e.response && e.response.data && e.response.data.message,
        "error"
      );
    } else {
      openErrorNotification(
        "Something went wrong, Please try again later",
        "error"
      );
    }
    return { success: false, error: e };
  }
};

const getVaccinesAndClinicNames = async function () {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get(
      `${configJSON.serverURL}/bx_block_vaccine/vaccines`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return { success: true, ...response.data };
  } catch (e) {
    if (e?.response?.data?.errors) {
      map(e.response.data.errors, (error) => {
        openErrorNotification(Object.values(error)[0], "error");
      });
    } else if (e?.response?.data?.message) {
      openErrorNotification(
        e && e.response && e.response.data && e.response.data.message,
        "error"
      );
    } else {
      openErrorNotification(
        "Something went wrong, Please try again later",
        "error"
      );
    }
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};

const getPassbooks = async function () {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.post(
      `${configJSON.serverURL}bx_block_passbook/passbooks`,
      {},
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          token: token,
        },
      }
    );
    return response.data;
  } catch (e) {
    if (e?.response?.data?.errors) {
      map(e.response.data.errors, (error) => {
        openErrorNotification(Object.values(error)[0], "error");
      });
    } else if (e?.response?.data?.message) {
      openErrorNotification(
        e && e.response && e.response.data && e.response.data.message,
        "error"
      );
    } else {
      openErrorNotification(
        "Something went wrong, Please try again later",
        "error"
      );
    }
    // const errorMsg = returnErrorMessage(e);
    return { success: false, error: e };
  }
};

export {
  create_recipient,
  getAccountDetails,
  getRecipientDetails,
  approve_request,
  getSurveyAnswer,
  approve_reject,
  getVaccinesAndClinicNames,
  getPassbooks,
};
