import React, { Component } from "react";
import CountryCodeSelector from "../../country-code-selector/src/CountryCodeSelector";
import { Link, withRouter } from "react-router-dom";
// Customizable Area End
import "./regstyle.css";
import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
import { doctor, recipient, signUpImage } from "./assets";
import { Formik } from "formik";
import ProgressBar from "react-bootstrap/ProgressBar";
export const userSignUp = require("./EmailAccountRegistrationService.web");

class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  onRegister = async (values: any) => {
    const { email, password, confirm_password } = values;
    let value = { email, password, confirm_password };
    const result = await userSignUp.userSignUp(value);
    if (result.success) {
      window.localStorage.setItem(
        "token",
        result && result.meta && result.meta.token
      );
      this.props.navigation.history.push(`/PromoDecksSignUpWeb`);
    } else {
      console.log("error from userSignUp:", result.error);
    }
  };

  onHandlePrivacyPolicy = (): void => {
    // @ts-ignore
    this.props.history.push("/privacy-policy");
  }

  onHandleTermsOfServices = (): void => {
    // @ts-ignore
    this.props.history.push("/terms-of-services");
  }

  render() {
    return (
      <div className="main-bg">
        <div className="container-fluid">
        <div className="row">
          <div className="col-6">
            <div className="hero-title">
              <h4>Covid Passport</h4>
            </div>
            <div className="page-image">
              <img className="hero-image" src={signUpImage} />
            </div>
          </div>
          <div className="col-6">
            <div className="sign-up">
              <span className="stay-safe">STAY SAFE</span>
              <h4 className="sign-up-title">Sign Up</h4>
              <span className="stay-safe mt-1">
                Already have an account ?{" "}
                <Link to="/EmailAccountLoginBlock" className="link-button">
                  Login Here
                </Link>
              </span>
              <div>
                <div className="pg-bar col-8 mt-4 mb-4">
                  <div className="row col-12">
                    <div className="col-6 pg-title">CREATE PROFILE </div>
                    <div className="col-6 pg-title">SET UP ACCOUNT</div>
                  </div>
                  <ProgressBar className="pg-bar2 mt-2 mb-4" now={50} />
                </div>
                <div className="row mt-2 selection">
                  <div className="recipient-tab1 col-3">
                    <input name="recipient" className="radio" type="radio" />
                    <span>Recipient</span>
                    <img
                      className="small-avatar"
                      src={recipient}
                      alt="recipient"
                    />
                  </div>
                  <div className="recipient-tab2 col-3">
                    <input name="recipient" className="radio" type="radio" />
                    <span className="">Clinic</span>
                    <img
                      className="small-avatar"
                      src={doctor}
                      alt="recipient"
                    />
                  </div>
                </div>
                <div className="mt-2 selection-text">
                  <span>Choose the one you resemble</span>
                </div>

                <div className="col-8 input-fields">
                  <Formik
                    initialValues={{
                      email: "",
                      fullName: "",
                      contactNumber: "",
                    }}
                    // validationSchema={signUpSchema1}
                    onSubmit={(values) => {
                      alert(values);
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      /* and other goodies */
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <input
                            className="text-input form-control"
                            type="text"
                            name="fullName"
                            placeholder="Full Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.fullName}
                          />
                          <span className="error">
                            {errors.fullName &&
                              touched.fullName &&
                              errors.fullName}
                          </span>
                        </div>

                        <div className="form-group">
                          <input
                            className="text-input form-control"
                            type="email"
                            name="email"
                            placeholder="Email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <span className="error">
                            {" "}
                            {errors.email && touched.email && errors.email}
                          </span>
                        </div>
                        <div className="form-group">
                          <input
                            className="text-input form-control"
                            type="number"
                            name="contactNumber"
                            placeholder="Contact Number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.contactNumber}
                          />
                          <span className="error">
                            {errors.contactNumber &&
                              touched.contactNumber &&
                              errors.contactNumber}
                          </span>
                        </div>
                        {/* <button
                          className="form-control button-input"
                          type="submit"
                          disabled={isSubmitting}
                          
                        >
                          SAVE & CONTINUE
                        </button> */}
                        <Link
                          className="form-control button-input"
                          to="/EmailAccountRegistration2"
                          style={{ textAlign: "center" }}
                        >
                          SAVE & CONTINUE
                        </Link>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>

              <div className="captcha col-8">
                <span>
                  The site is protected by reCAPTCHA and the google{" "}
                  <span className="link-text" onClick={this.onHandlePrivacyPolicy}>Privacy Policy</span>
                  and <span className="link-text" onClick={this.onHandleTermsOfServices}>Terms</span> of Service apple.
                </span>
              </div>
            </div>
          </div>
        </div>
        </div>
      
      </div>
    );
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getValidations();
    // Customizable Area End
  }
}

// @ts-ignore
export default withRouter(EmailAccountRegistration);