export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const heroImage = require("../assets/heroImage.png");
export const check = require("../assets/CHECK.png");
export const danger = require("../assets/danger.png");
export const download = require("../assets/download.png");
export const UploadIcon = require('../assets/upload-icon.png');
export const apple = require("../assets/apple.png");

export const gpay = require("../assets/gpay.png");
export const location = require("../assets/location.png");
export const closeImage = require('../assets/close.png')
export const bg1 = require('../assets/bg-1.png')
export const arrow = require('../assets/arrow.png')
export const userIcon = require('../assets/user-icon.png')
