import React, { useState, useRef } from "react";
import { imgVector } from "./assets";
import "../../scss/style.scss";
import "./scss/index.scss";
import { withRouter, Redirect } from "react-router-dom";
import { openNotification } from "./Notification.web";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { isEmpty } from "../../../framework/src/Utilities";
import OtpInput from 'react-otp-input';

export const otpVerification = require("./ForgotPasswordService.web");

const schema = require("./validationSchema/signinSchema.web");
function ForgotPasswordOtp(props: any) {
  const [firstValue, setFirstValue] = useState("");
  const [secondValue, setSecondValue] = useState("");
  const [thirdValue, setThirdValue] = useState("");
  const [fourthValue, setFourthValue] = useState("");
  const [otp, setOtp] = useState("");

  const handleOtp = async (values: any) => {
    if (otp.length !== 4) {
      return
    }
    values = {
      otp: parseInt(otp),
    };
    console.log(values);
    const res = await otpVerification.otpVerification(values);
    if (res.success) {
      if (res?.meta?.token) {
        localStorage.setItem("token", res?.meta?.token);
      }
      openNotification("Otp Verified Successfully, Please login", "success");
      props.history.push({
        pathname: '/NewPassword'
      });
    }
  };

  // const onHandleChangeFirstValue = (event: any) => {
  //   if (event.target?.value?.length < 2) {
  //     setFirstValue(event.target.value);
  //   }
  // }

  // const onHandleChangeSecondValue = (event: any) => {
  //   if (event.target?.value?.length < 2) {
  //     setSecondValue(event.target.value);
  //   }
  // }
  // const onHandleChangeThirdValue = (event: any) => {
  //   if (event.target?.value?.length < 2) {
  //     setThirdValue(event.target.value);
  //   }
  // }
  // const onHandleChangeFourthValue = (event: any) => {
  //   if (event.target?.value?.length < 2) {
  //     setFourthValue(event.target.value);
  //   }
  // }

  const resendHandler = async () => {
    let values: any = {
      value: props.location?.data?.values?.email || props.location?.data?.values?.phone_number,
    };
    if (values?.value?.includes("@")) {
      values = { email: values.value };
    }
    else {
      values = { phone_number: values.value };
    }
    const result = await otpVerification.userForgotPass(values);
    if (result.success) {
      if (result?.meta?.token) {
        localStorage.setItem("token", result.meta.token)
      }
      openNotification("OTP sent on your registered email address", "success");

    } else {
      console.log("error from userSignUp:", result.error);
    }
  };

  const onHandlePrivacyPolicy = (): void => {
    props.history.push("/privacy-policy");
  }

  const onHandleTermsOfServices = (): void => {
    props.history.push("/terms-of-services");
  }

  if (isEmpty(props.location?.data?.values?.email)) {
    return <Redirect to="/ForgotPassword" />
  }

  return (

    <div className="main-bg">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="hero-title">
              <h4 className="hero-title">Covid Passport</h4>
            </div>
            <div className="page-image">
              <img className="hero-image" src={imgVector} />
            </div>
          </div>
          <div className="col-md-6 col-sm-12 form-section">
            <div className="sign-up">
              <span className="stay-safe-forgot-password">STAY SAFE</span>
              <h4 className="sign-up-title-forgot-password">OTP Verification</h4>
              {!isEmpty(props.location?.data?.values?.email) && (
                <p className="otp-sent-email-forgot-password"> Otp has been sent to you on this email {props.location?.data?.values?.email} </p>
              )}
              {/* <div className="col-8 ml-4 otp-form"> */}
              <Formik
                initialValues={{}}
                validationSchema={Yup.object().shape(
                  schema.otp
                )}
                onSubmit={(values) => {
                  handleOtp(values);
                }}
                enableReinitialize
              >
                {({ }) => (
                  //@ts-ignore
                  <Form>
                    <p >
                      {/* Enter the code sent on example@gmil.com */}
                    </p>{" "}
                    <div className="my-otp-forgot-password mt-1 mb-1">
                      <OtpInput
                        className="my-otp"
                        value={otp}
                        onChange={(value: any) => {
                          setOtp(value)
                        }}
                        numInputs={4}
                      />
                    </div>
                    <div className="flex-center mt-2">
                      <p style={{ fontSize: '15px' }}>Didn’t receive Code ?</p>{" "}
                      <a
                        className="link ml-2"
                        onClick={resendHandler}
                      >
                        Resend Code
                      </a>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-grey w-100 mt-2 submit-btn"
                    >
                      SUBMIT & CONTINUE
                    </button>
                  </Form>
                )}
              </Formik>
              {/* </div> */}
              <div className="reset-captcha">
                The site is protected by reCAPTCHA and the google{" "}
                <span className="link-text" onClick={onHandlePrivacyPolicy}>Privacy Policy </span>
                and <span className="link-text" onClick={onHandleTermsOfServices}>Terms of Service </span> apple.
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>



  );
}




export default withRouter(ForgotPasswordOtp);
