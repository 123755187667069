import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom"
import { heroImage, danger, check, apple, gpay, download, bg1 } from "./assets";
import Navigation from "./Navigation.web";
// @ts-ignore
import isEmpty from "lodash/isEmpty";
import "./consolestyle.css";
const QRCode = require("qrcode.react");
export const ClinicAPI = require("./ClinicService.web");
export const configJSON = require('./config')
var createTemplate = require("passbook");
var fs = require('fs')
// const { createPass } = require("passkit-generator");
// import { Stream } from "stream";
import path from "path";
import { openNotification } from "./Notification.web";
var domtoimage = require('dom-to-image');

var pkPass = require('./apple/passModels/pass1.pkpass')



function UserConsole(props: any) {
  const [consoleState, setConsoleState] = useState(0);
  const [name, setName] = useState(window.localStorage.getItem("Name"));
  const [data, setData] = useState<any>({})


  // console.log(path.resolve(__dirname, "./apple/certs/Certificates.pem"))

  useEffect(() => {
    checkIsUserSubmitSurvey();
  }, [])


  useEffect(() => {

    if (!isEmpty(data)) {

      if (data?.is_slot_booked !== null && (data?.is_slot_booked).toUpperCase() === 'NO' && (data?.is_vaccinated).toUpperCase() === 'NO') {
        setConsoleState(1)
      }
      if (data?.is_slot_booked !== null && data.is_slot_booked.toUpperCase() === 'YES' && data.is_vaccinated.toUpperCase() === 'NO') {
        setConsoleState(2)
      }
      if (data?.is_slot_booked !== null && data.is_slot_booked.toUpperCase() === 'NO' && data.is_vaccinated.toUpperCase() === 'YES' && data.vaccine_registration?.data?.attributes?.approval_status.toUpperCase() === 'PENDING') {
        setConsoleState(2)
      }
      if (data.vaccine_registration?.data?.attributes?.approval_status.toUpperCase() === 'PENDING') {
        setConsoleState(2)
      }

      if (data.vaccine_registration?.data?.attributes?.approval_status.toUpperCase() === 'SUBMITTED FOR CLINIC REVIEW') {
        setConsoleState(2)
      }

      if (data.vaccine_registration?.data?.attributes?.approval_status.toUpperCase() === "REJECTED") {
        setConsoleState(3)
      }
      if (data.vaccine_registration?.data?.attributes?.approval_status.toUpperCase() === "APPROVED") {
        setConsoleState(4)
      }
    }

  }, [data])

  const checkIsUserSubmitSurvey = async () => {
    const result = await ClinicAPI.getSurveyAnswer();
    if (result.success) {
      setData(result)
      if (!result?.is_survey_completed) {
        props.history.push("/SurveyPersonalDetails")
      }
    } else {
      console.log("error from create recipient:", result.error);
    }
  }

  const shouldHideContinueHereButton = (): boolean => {
    return data?.is_survey_completed &&
      data?.vaccine_registration?.data?.attributes?.approval_status.toUpperCase() === "PENDING" || data.vaccine_registration?.data?.attributes?.approval_status.toUpperCase() === 'SUBMITTED FOR CLINIC REVIEW' &&
      !isEmpty(data?.vaccine_registration?.data?.attributes.vaccine_proof)
  }
  const createApplePass = async (type: any) => {
    const result = await ClinicAPI.getPassbooks();
    if (result.message !== "") {
      {
        if (type === 'apple') {
          openNotification("We have successfully sent your apple pass on your registered email ID.", 'success')
        } else {
          openNotification("We have successfully sent your android pass on your registered email ID.", 'success')

        }
      }
      // openNotification(result.message, 'success')
    }
    // const url = window.URL.createObjectURL(new Blob([result]));
    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute("download", "file.pkpass")
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  }

  const downloadTicket = () => {
    domtoimage.toJpeg(document.getElementById('e-ticket'), { quality: 2.95 })
      .then(function (dataUrl: any) {
        var link = document.createElement('a');
        link.download = `${window.localStorage.getItem("Name")}-covid-passport.jpeg`
        link.href = dataUrl;
        link.click();
      });
  }



  return (
    <div className="container">
      <div className="main-bg container">
        <Navigation />
        <div className="container-fluid">
          <div className="repeated-section container mt-4">
            <div className="vaccination-detail">
              <p className="user-name">
                Hello, <span>{name}</span>
              </p>
              <h3 className='welcome-text'>Welcome to your Dashboard</h3>
              <p className="covide-infomation">
                All your Covid-19 information will be displayed here.
              </p>
              <img src={bg1} className="background-image" />
            </div>
          </div>
          <div className="col-12 row details-section">
            <div className="col-5 ds-section1">
              {/* <div className="hero-title">
              <span>Covid Passport</span>
            </div> */}
              <div className="">
                <img className="hero-image" src={heroImage} />
              </div>
            </div>
            <div className="col-7 ds-section2">
              <div className="main-panel">
                <div className="col-12 row main-panel-section1">
                  <div className="col-12 sction-part1">
                    <div className="box-1">
                      {consoleState === 1 && (
                        <div>
                          <h4 className="text1-main">
                            Get Vaccinated <img src={danger} />
                          </h4>
                          <div className="text1-sub" style={{ marginLeft: '4%', width: '69%', marginTop: '4%' }}>
                            If you have not been vaccinated, book your vaccination
                            slot <span style={{ color: "#f5a281" }}>NOW!</span>
                          </div>
                        </div>
                      )}
                      {consoleState === 2 && (
                        <div>
                          <h4 className="text1-main">Get Vaccinated</h4>
                          <div className="text1-sub1" style={{ width: '69%', marginTop: '4%' }}>
                            You have booked your vaccination slot!
                          </div>
                        </div>
                      )}
                      {consoleState === 3 && (
                        <div>
                          <h4 className="text1-main">
                            Vaccinated <img src={check} width="5%" />
                          </h4>
                          <div className="text1-sub1" style={{ width: '69%', marginTop: '4%' }}>
                            You have been vaccinated and are safe!
                            Take Covid Precautions to fight the virus.
                          </div>
                        </div>
                      )}
                      {consoleState === 4 && (
                        <div>
                          <h4 className="text1-main">
                            Vaccinated <img src={check} width="5%" />
                          </h4>
                          <div className="text1-sub1" style={{ width: '69%', marginTop: '4%' }}>
                            You have been vaccinated and are safe! Take Covid
                            Precautions to fight the virus.
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="box-2">
                      <h4 className="text2-main">{consoleState === 4 ? "Covid Passport" : "Covid Passport"}</h4>
                      {(consoleState === 1) && (
                        <div>
                          <span className="text2-sub1">Application status:</span>
                          <span className="text2-sub2">Pending</span>
                          <div className='text-button'>
                            {!shouldHideContinueHereButton() && <button className="btn cnt-here" onClick={() => { props.history.push("/ClinicSurveyScreen") }}>
                              CONTINUE HERE
                            </button>}
                          </div>
                        </div>
                      )}
                      {consoleState === 2 && (
                        <div>
                          <span className="text2-sub1">Application status:</span>
                          <span className="text2-sub2">{data.vaccine_registration?.data?.attributes?.approval_status || "Pending"}</span>
                          <div className='status-div'>
                            {!shouldHideContinueHereButton() && <button className="btn cnt-here" onClick={() => { props.history.push("/ClinicSurveyScreen") }}>
                              CONTINUE HERE
                            </button>}
                          </div>
                        </div>
                      )}

                      {consoleState === 3 && (
                        <div>
                          <span className="text2-sub1">Application status:</span>
                          <span className="text2-sub2">Rejected</span>
                          <h4 className="text1-sub" style={{ paddingTop: "1%" }}>
                            <img src={danger} width="5%" /> Clinic Approval was
                            rejected.
                          </h4>
                          <div className="rejected-reason">
                            <span className="text2-sub1">
                              Reason :
                            </span>
                            <span className="reason-text mt-4 ml-2">
                              {data.vaccine_registration?.data?.attributes?.reason}
                            </span>
                          </div>
                          <div>
                            <button className="btn cnt-here" onClick={() => { props.history.push("/ClinicSurveyScreen") }}>
                              SUBMIT AGAIN
                            </button>
                          </div>
                        </div>
                      )}

                      {consoleState === 4 && (
                        <div className="row">
                          <div className="col-6">
                            <span className="text2-sub1 ml-2">Application status:</span>
                            <span className="text2-sub3">Verified</span>
                            <div>
                              <br />
                              <div className="rejected-reason">
                                <span className="text2-sub1 ml-2">
                                  Reason:
                                </span>
                                <span className="reason-text mt-4 ml-2">
                                  {data.vaccine_registration?.data?.attributes?.reason}
                                </span>
                              </div>
                            </div>
                            <div className="mt-5 ml-3">
                              <button onClick={() => createApplePass('apple')} className="btn btn-1">
                                <div className="row">
                                  <div className="col-2">
                                    <img src={apple} />
                                  </div>
                                  <label className="col-10 mt-1" style={{ fontSize: '14px' }}>Add to Apple Wallet</label>
                                </div>
                              </button>
                              <button onClick={() => createApplePass('android')} className="btn btn-1">
                                <div className="row">
                                  <div className="col-2">
                                    <img src={gpay} />
                                  </div>
                                  <label className="col-10 mt-1" style={{ fontSize: '14px' }}>Add to Android Wallet</label>
                                </div>
                              </button>
                              <button onClick={() => downloadTicket()} className="btn btn-1">
                                <div className="row">
                                  <div className="col-2">
                                    <img src={download} />
                                  </div>
                                  <label className="col-10 mt-1" style={{ fontSize: '14px' }}>Download E-ticket</label>
                                </div>
                              </button>
                            </div>
                          </div>
                          <div className="col-6" id="e-ticket">
                            <div className="e-ticket" >
                              <div className="e-ticket-sub">
                                <p className="tkt-title">Covid Passport</p>
                                <table className="tkt-table">
                                  <tr>
                                    <td className="tkt-head">Registration ID:</td>
                                    <td className="tkt-value">{data.vaccine_registration?.data?.attributes?.recipient?.data?.id}</td>
                                  </tr>
                                  <tr>
                                    <td className="tkt-head">Vaccine Name:</td>
                                    <td className="tkt-value" title={data.vaccine_registration?.data?.attributes?.vaccine?.data?.attributes?.name}>{data.vaccine_registration?.data?.attributes?.vaccine?.data?.attributes?.name}</td>
                                  </tr>
                                  <tr>
                                    <td className="tkt-head">Clinic Name:</td>
                                    <td className="tkt-value" title={data.vaccine_registration?.data?.attributes?.clinic?.data?.attributes?.first_name}>{data.vaccine_registration?.data?.attributes?.clinic?.data?.attributes?.first_name.slice(0, 20) + (data.vaccine_registration?.data?.attributes?.clinic?.data?.attributes?.first_name.length > 20 ? "..." : "")}</td>
                                  </tr>
                                  <tr>
                                    <td className="tkt-head">Clinic Address:</td>
                                    <td className="tkt-value" title={data.vaccine_registration?.data?.attributes?.clinic?.data?.attributes?.residential_address}>
                                      {data.vaccine_registration?.data?.attributes?.clinic?.data?.attributes?.residential_address.slice(0, 20) + (data.vaccine_registration?.data?.attributes?.clinic?.data?.attributes?.residential_address.length > 20 ? "..." : "")}
                                    </td>
                                  </tr>
                                </table>
                                <hr className="qr-line" />
                                <div className="qr-code">
                                  {/* {console.log(`${configJSON.serverURL}bx_block_vaccine/vaccines/get_qr_details?account_id=${window.localStorage.getItem('recipientId')}`)} */}
                                  <QRCode
                                    style={{ width: 'auto' }}
                                    value={`${configJSON.serverURL}bx_block_vaccine/vaccines/get_qr_details?account_id=${window.localStorage.getItem('recipientId')}`}
                                  />
                                </div>
                                <hr className="qr-line" />
                                <div className="row ml-3 mt-3">
                                  <span className="end-text">
                                    Covid-19 Vaccine{" "}
                                  </span>
                                  <img className="ml-2" width="10%" style={{ marginTop: '-3%' }} src={check} />

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {/* <div className="col-4 sction-part2">
                  <div className="box-3" />
                  <div className="box-4">
                    <h4 className="text4-main">FAQ's</h4>
                    <div className='question-list'>
                      <ul>
                        <li className="text4-sub">
                          Can we get vaccinated if we have been affected with it
                          recently?
                        </li>
                        <li className="text4-sub">
                          Can we get vaccinated if we have been affected with it
                          recently?
                        </li>
                      </ul>
                      <div className='view-more-div'>
                        <button className="btn view-more">View More</button>
                      </div>
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(UserConsole);
